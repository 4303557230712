import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import MovieScene from 'components/MovieScene';
import scene from 'components/scene';
import ActorPortrait from 'components/ActorPortrait';
import {modalWindowBackgrounds} from 'components/ModalContent';
import Clip from './ch5-SCENE-08-MLK_Calls_Activists_To_Come_To_Selma.mp4';

const Chapter5Scene8 = ({data}) => (
  <div className="chapter-5-scene-8">
    <MovieScene
      sceneNumber={8}
      sceneSubtitle={
        <span><span className="italic">SELMA</span> SCENE 8</span>
      }
      sceneDescription="MLK calls for Clergy and Activists to come to Selma"
      firstImage={data.MlkCallStill}
      lastImage={data.MlkCallStill}
      videoSrc={Clip}
    />

  </div>
);

const query = graphql`
  query {
    MlkCallStill: file(relativePath: { eq: "chapter-5/scene-8/images/ch-5-image-mlkcallsforclergy-still@2x.png" }) {
      ...largeImage
    }
  }
`;

const ChapterComponent = scene(Chapter5Scene8);
export default (props) => {
  const data = useStaticQuery(query);
  return <ChapterComponent data={data} {...props}/>;
};
