import React from 'react';
import PropTypes from 'prop-types';
import CSSPlugin from 'gsap/CSSPlugin'; // eslint-disable-line no-unused-vars
import timeline from 'components/timelineComponent';

import {TweenMax} from 'gsap';

import 'styles/components/calendar-strip.scss';
import xMarkDoodle from '../img/ch-6-doodle-x@2x.png';

const TARGET_DATE_STRING = 'is-target-date';

const CalendarStrip = class extends React.Component {
  componentDidMount() {
    const {timeline} = this.props;

    const targetDate = this.el.querySelector(`.${TARGET_DATE_STRING}`);

    if (targetDate) {
      const child = targetDate.childNodes[0];
      const neighbor = targetDate.nextSibling;
      TweenMax.set(targetDate, {css: {zIndex: 1, borderWidth: '0px'}});
      TweenMax.set(neighbor, {css: {borderLeftWidth: '0px'}});
      TweenMax.set(child, {css: {border: '2px solid white'}});

      timeline.to(targetDate, 1, {scale: 1.2});
      timeline.to(child, 1, {css: {border: '4px solid #fc1930', backgroundColor: 'black'}}, '-=1');
    }
  }

  render() {
    const {dates} = this.props;
    return (
      <div ref={(ref) => this.el = ref} className="calendar-strip">
        <ul className="calendar-strip__dates">
          {dates.map((date, index) => {
            const {number, dateText, crossedOut, lowerText, targetDate} = date;
            const targetString = targetDate ? TARGET_DATE_STRING : '';
            return (
              <li key={index} className={`calendar-strip__dates__date ${targetString}`}>
                <div className="calendar-strip__dates__date__container">
                  <div className="calendar-strip__dates__date__content">
                    <p className="number">{number}</p>
                    {dateText &&
                      <p className="date-text">{dateText}</p>
                    }

                    {crossedOut &&
                      <img className="crossOut" src={xMarkDoodle} />
                    }

                    {lowerText &&
                      <p className="lower-text">{lowerText}</p>
                    }
                  </div>
                </div>

              </li>
            );
          })}
        </ul>
      </div>
    );
  }
};

CalendarStrip.propTypes = {
  dates: PropTypes.arrayOf(PropTypes.shape({
    number: PropTypes.number.isRequired,
    dateText: PropTypes.string,
    crossedOut: PropTypes.bool,
    lowerText: PropTypes.string,
  })).isRequired,
};

export default timeline(CalendarStrip);
