import React, { Component } from 'react';
import { useStaticQuery, graphql } from "gatsby"

import SplitView from 'templates/SplitView';
import { Image } from 'components/Image';
import Timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import ScrollContainer from 'components/ScrollContainer'
import {tabbable} from 'utils/accessibility'

import {
  placeholder, hideAllRefs, showElemWithAria, hideElementWithAria, makeElementTabbable, unmakeElementTabbable
} from 'utils/animation';
import { getPercentageBetweenBounds } from 'utils';
import scene from 'components/scene';
import {TimelineMarkers} from 'utils/scene'

import './chapter-5-scene-7.scss';
import ImageSwapAngle from 'components/ImageSwapAngle';
import { addTab, removeTab } from '../../../../utils/animation';

const imageSwapStart = 0;
const imageSwapEnd = 0.1;

class Chapter5Scene7 extends Component {
  render() {
    const { position, data } = this.props
    const {
      Horses, RiotBw, RiotRed, Amelia, AmeliaRed, LewisRed, Lewis,
      Aftermath1, Aftermath2, Aftermath3, Aftermath4,
      NewsCaster, WhiteFamily, BlackFamily, Quote

    } = data
    const imageSwapProgress = getPercentageBetweenBounds(position, imageSwapStart, imageSwapEnd)

    return (
      <div className="chapter-5-scene-7">
        <div className="troopers" ref="troopers">
          <FullScreenContainer>
            <div className="troopers__bg">
              <ImageSwapAngle
                position={imageSwapProgress}
                image={RiotBw}
                image2={RiotRed}
                offsetLeft={30}
                showAria2
                alt=""
                alt2="Armed police officers in gas masks charge at demonstrators at the base of the Edmund Pettus Bridge during the first Selma to Montgomery March, Selma, Alabama, March 7, 1965. Among those pictured are march leaders Amelia Boynton (1911 - 2015) (on ground at left, in light coat), who was knocked unconscious, and John Lewis (right, with long, light coat and backpack), who was beaten several moment later. (Photo by Charles Moore/Getty Images)"
              />
            </div>
            <div className="troopers__text">
              <h3 {...tabbable}>
                None of them are prepared for the unrelenting violence of the Alabama state troopers who charge into the marchers, disabling them with tear gas and clubbing them mercilessly.
              </h3>
            </div>
          </FullScreenContainer>
        </div>

        <div className="horses" ref="horses">
          <SplitView
            leftPanel={(
              <div {...tabbable} className="horses__text" ref="horsesText">
                <h4>
                  The marchers retreat to seek sanctuary in Brown Chapel, but the troopers and posse chase them and continue to beat them with nightsticks, ignoring their screams and appeals to stop.
                </h4>
              </div>
            )}
            rightPanel={(
              <div className="horses__img" ref="horsesImg" aria-label="USA SELMA BLOODY SUNDAY">
                <Image image={Horses} alt="Horses"/>
              </div>
            )}
          />
        </div>

        <div className="beaten" ref="beaten">
          <SplitView
            leftPanel={(
              <div className="beaten__left">
                <div className="bgs">
                  <div className="bgs__red" ref="beatenAmelia" >
                    <Image image={Amelia} alt=""/>
                  </div>
                  <div className="bgs__bw" ref="beatenAmeliaRed" aria-label="Injured Civil Rights Marchers
A civil rights marcher suffering from exposure to tear gas, holds an unconscious Amelia Boynton Robinson after mounted police officers attacked marchers in Selma, Alabama as they were beginning a 50 mile march to Montgomery to protest race discrimination in voter registration." role='img'>
                    <Image image={AmeliaRed} alt=""/>
                  </div>
                </div>
                <div className="txt">
                  <p ref="beatenAmeliaText" className="subhead">
                    Amelia Boynton of the Dallas County<br/>Voters League is rendered unconscious<br/>by troopers’ night sticks and tear gas.
                  </p>
                </div>
              </div>
            )}
            rightPanel={(
              <div className="beaten__right">
                <div className="bgs">
                  <div className="bgs__red" ref="beatenLewis">
                    <Image image={Lewis} alt=""/>
                  </div>
                  <div className="bgs__bw" ref="beatenLewisRed" role='img' aria-label="Selma, Alabama: End Of The March. SNCC leader John Lewis (light coat, center), attempts to ward off the blow as a burly state trooper swings his club at Lewis' head during the attempted march from Selma to Montgomery March 7th. Lewis was later admitted to a local hospital with a possible skull fracture.">
                    <Image image={LewisRed} alt=""/>
                  </div>
                </div>
                <div className="txt" >
                  <p {...tabbable} ref="beatenLewisText" className="subhead">
                    John Lewis of SNCC is struck by a<br/>vicious blow to his head.<br/><br/>
                  </p>
                </div>
              </div>
            )}
          />
        </div>

        <div className="aftermath" ref="aftermath">
          <FullScreenContainer className="content">
            <div className="side" >
              <div className="polaroid p1">
                <Image image={Aftermath1} alt="Bloody Sunday Aftermath"/>
              </div>
              <div className="polaroid p2">
                <Image image={Aftermath2} showAria alt="The Selma march 1965 Selma Alabama A young victim in Bloody Sunday is carried to an aid station"/>
              </div>

            </div>
            <ScrollContainer className="middle">
              <p {...tabbable} className="subhead" ref="aftermathTitle">
                In the aftermath of Bloody Sunday, an estimated 58-80 of the marchers receive treatment for their wounds, but many more are injured.
              </p>
              <p {...tabbable} className="alt" ref="aftermathParagraph">
                Millions of Americans were just sitting down to watch the ABC Sunday Night Movie: <span className="italic">Judgment at Nuremburg</span>—a film about post-war trials of German Nazis—when the station's news division broke in with recorded footage of racist violence much closer to home: Selma, Alabama.
              </p>
            </ScrollContainer>
            <div className="side">
              <div className="polaroid p3">
                <Image image={Aftermath3} showAria alt="FILE - In this March 7, 1965, file photo, Amelia Boynton is carried and another injured man tended to after they were injured when state police broke up a demonstration march in Selma, Ala. Boynton, wife of a real estate and insurance man, has been a leader in civil rights efforts. The day, which became known as 'Bloody Sunday,' is widely credited for galvanizing the nation's leaders and ultimately yielded passage of the Voting Rights Act of 1965. (AP Photo/File)"/>
              </div>
              <div className="polaroid p4">
                <Image image={Aftermath4} showAria alt="3/8/1965-Selma, AL: John Lewis, head of the Student Non-violent Coordinating Committee, lies immobile in the Good Samaritan Hospital in Selma, AL. after suffering a possible head fracture in 3/7 march by Negroes ehich was stopped by State Police with tear gas and clubs."/>
              </div>

            </div>
          </FullScreenContainer>
        </div>

        <div className="coverage">
          <div className="coverage__tv" ref="coverageTv">
            <SplitView
              fullWidthPanel={(
                <div className="newscaster" ref="coverageTvBg">
                  <Image image={NewsCaster} alt="news caster"/>
                </div>
              )}
              rightPanel={(
                <p {...tabbable} className="subhead" ref="coverageTvParagraph">
                  <span className="highlight">Millions of Americans</span> watch these scenes unfold on television and are shocked at the brutality of the Alabama state troopers.
                </p>
              )}
            />
          </div>
          <div className="coverage__lewis quote-view" ref="coverageLewis">
            <SplitView
              fullWidthPanel={(
                <div className="family" ref="coverageLewisBg">
                  <Image image={WhiteFamily} alt="White Family"/>
                </div>
              )}
              leftPanel={(
                <div className="lewis-quote quote-text">
                  <div className="quote" ref="coverageLewisQuote">
                    <Image image={Quote} alt="Quote"/>
                  </div>
                  <div {...tabbable}>
                    <p className="paragraph subhead" ref="coverageLewisParagraph">
                      I don’t see how President Johnson can send troops to Vietnam—I don’t see how he can send troops to the Congo—I don’t see how he can send troops to Africa and can’t send troops to Selma
                    </p>
                    <h5 ref="coverageLewisName">John Lewis</h5>
                    <p className="caption" ref="coverageLewisCaption">New York Times, 8 March 1965</p>
                  </div>
                </div>
              )}
            />
          </div>

          <div className="coverage__williams quote-view" ref="coverageWilliams">
            <SplitView
              fullWidthPanel={(
                <div className="family" ref="coverageWilliamsBg">
                  <Image image={BlackFamily} alt="Black Family"/>
                </div>
              )}
              rightPanel={(
                <div className="williams-quote quote-text">
                  <div className="quote" ref="coverageWilliamsQuote">
                    <Image image={Quote} alt="Quote"/>
                  </div>
                  <div {...tabbable}>
                    <p className="paragraph subhead" ref="coverageWilliamsParagraph">
                      I fought in World War II and I once was captured by the German army, and I want to tell you that the Germans never were as inhuman as the state troopers of Alabama.
                    </p>
                    <h5 ref="coverageWilliamsName">Hosea Williams</h5>
                  </div>
                </div>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

const animation = ({context, timeline, easeOut, easeIn}) => {


  const {
    troopers,
    horses, horsesText, horsesImg,
    beaten, beatenAmelia, beatenAmeliaRed, beatenAmeliaText,
    beatenLewis, beatenLewisRed, beatenLewisText,
    aftermath, aftermathTitle, aftermathParagraph,
    coverageTv, coverageTvBg, coverageTvParagraph,
    coverageLewis, coverageLewisBg, coverageLewisQuote, coverageLewisParagraph, coverageLewisName,
    coverageLewisCaption,
    coverageWilliams, coverageWilliamsBg, coverageWilliamsQuote, coverageWilliamsParagraph, coverageWilliamsName,


  } = context.refs;

  hideAllRefs(context.refs, [
    'troopers', 'horsesImg', 'beatenAmelia',
    'coverageTvBg', 'coverageLewisBg', 'coverageWilliamsBg',
  ]);

  placeholder(timeline, 2);

  timeline.to(troopers, 1, {...hideElementWithAria});
  timeline.to([horses], 1, {...showElemWithAria}, '-=1');
  timeline.to([horsesImg], 0.01, {...addTab}, '-=1');
  timeline.fromTo(horsesText, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}
  );

  timeline.markers.createTimelineMarker()

  timeline.to([horsesImg, horses], 1, {...hideElementWithAria, ...removeTab});
  timeline.to(beaten, 1, {...showElemWithAria}, '-=0.5');
  timeline.to(beatenAmeliaRed, 0, {...addTab});
  timeline.to(beatenAmeliaText, 0, {...makeElementTabbable})
  timeline.fromTo(beatenAmeliaText, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=0.5"
  );
  timeline.to(beatenAmeliaRed, 1, {...showElemWithAria}, "-=1.5");
  timeline.markers.createTimelineMarker()
  timeline.to(beatenAmeliaRed, 0, {...removeTab});
  timeline.to(beatenLewis, 1, {...showElemWithAria});
  timeline.to(beatenAmeliaText, 0, {...unmakeElementTabbable}, "-=0.99")

  timeline.to(beatenLewisRed, 1, {...showElemWithAria, ...addTab}, "-=0.5");
  timeline.fromTo(beatenLewisText, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=1"
  );

  timeline.markers.createTimelineMarker()

  timeline.to(beaten, 1, {...hideElementWithAria});
  timeline.to(aftermath, 1, {...showElemWithAria}, '-=0.5');

  timeline.fromTo(aftermathTitle, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=0.5"
  );
  timeline.fromTo(aftermathParagraph, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=1.25"
  );

  timeline.markers.createTimelineMarker()

  timeline.to(aftermath, 1, {...hideElementWithAria});
  timeline.to(coverageTv, 1, {...showElemWithAria}, '-=0.5');
  timeline.fromTo(coverageTvParagraph, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=0.5"
  );
  timeline.markers.createTimelineMarker()

  timeline.to(coverageTv, 1, {...hideElementWithAria});
  timeline.to(coverageLewis, 1, {...showElemWithAria}, '-=0.5');
  timeline.fromTo(coverageLewisQuote, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=0.5"
  );
  timeline.fromTo(coverageLewisParagraph, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=1.25"
  );
  timeline.fromTo(coverageLewisName, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=1.25"
  );
  timeline.fromTo(coverageLewisCaption, 1.5,
    {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=1.5"
);
  timeline.markers.createTimelineMarker()

  timeline.to(coverageLewis, 1, {...hideElementWithAria});
  timeline.to(coverageWilliams, 1, {...showElemWithAria}, '-=1');
  timeline.fromTo(coverageWilliamsQuote, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=0.5"
  );
  timeline.fromTo(coverageWilliamsParagraph, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=1.25"
  );
  timeline.fromTo(coverageWilliamsName, 1.5,
    {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=1.25"
  )


}

const query = graphql`
  query {
    Horses: file(relativePath: { eq: "chapter-5/scene-7/images/ch-5-image-bloody-sunday-horses@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    RiotBw: file(relativePath: { eq: "chapter-5/scene-7/images/ch5-image-bloody_sunday_riot-bw.jpg" }) {
      ...largeImage
    }

    RiotRed: file(relativePath: { eq: "chapter-5/scene-7/images/ch5-image-bloody_sunday_riot-red.jpg" }) {
      ...largeImage
    }

    Amelia: file(relativePath: { eq: "chapter-5/scene-7/images/ch-5-image-bloody-sunday-amelia.png" }) {
      ...mediumImage
    }

    AmeliaRed: file(relativePath: { eq: "chapter-5/scene-7/images/ch-5-image-bloody-sunday-amelia-red.png" }) {
      ...mediumImage
    }

    LewisRed: file(relativePath: { eq: "chapter-5/scene-7/images/ch-5-image-bloody-sunday-lewis-beaten-red@2x.png" }) {
      ...mediumImage
    }

    Lewis: file(relativePath: { eq: "chapter-5/scene-7/images/ch-5-image-bloody-sunday-lewis-beaten@2x.png" }) {
      ...mediumImage
    }

    Aftermath1: file(relativePath: { eq: "chapter-5/scene-7/images/ch-5-image-bloody-sunday-aftermath-1@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 712) {
          ...selmaFluidImage
        }
      }
    }

    Aftermath2: file(relativePath: { eq: "chapter-5/scene-7/images/ch-5-image-bloody-sunday-aftermath-2@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 712) {
          ...selmaFluidImage
        }
      }
    }

    Aftermath3: file(relativePath: { eq: "chapter-5/scene-7/images/ch-5-image-bloody-sunday-aftermath-3@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 716) {
          ...selmaFluidImage
        }
      }
    }

    Aftermath4: file(relativePath: { eq: "chapter-5/scene-7/images/ch-5-image-bloody-sunday-aftermath-4@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 616) {
          ...selmaFluidImage
        }
      }
    }

    NewsCaster: file(relativePath: { eq: "chapter-5/scene-7/images/ch-5-image-newscaster@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 2122) {
          ...selmaFluidImage
        }
      }
    }

    WhiteFamily: file(relativePath: { eq: "chapter-5/scene-7/images/ch-5-image-whitefamily-tv@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 2880) {
          ...selmaFluidImage
        }
      }
    }

    BlackFamily: file(relativePath: { eq: "chapter-5/scene-7/images/ch-5-image-blackfamily-tv@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 2436) {
          ...selmaFluidImage
        }
      }
    }

    Quote: file(relativePath: { eq: "chapter-2/scene-9/images/quote@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 72) {
          ...selmaFluidImage
        }
      }
    }
  }
`

const SceneComponent = scene(Timeline(Chapter5Scene7, {animation}), {stopAtStart: true})
export default (props) => {
  const data = useStaticQuery(query)
  return <SceneComponent data={data} {...props}/>
}
