import React from 'react';
import FullScreenContainer from 'components/FullScreenContainer';
import VideoPlayer from 'components/VideoPlayer';
import {getPercentageBetweenBounds} from 'utils';

import 'styles/components/video-animation.scss';

const fps = 24;

const VideoAnimation = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSection: 0,
      isReady: false
    };
    this.handleVideoReady = this.handleVideoReady.bind(this);
  }

  componentWillUnmount() {

    if (this.handleVideoUpdate) {
      this.killUpdateListener();
    }
  }

  componentWillReceiveProps({progress, sections}) {
    const section = sections.findIndex((section) => progress > section.breakpoints[0] && progress <= section.breakpoints[1] );
    if (section !== this.state.activeSection) {
      this.makeActive(section);
    }
  }

  componentDidUpdate({}, {activeSection: prevActiveSection, isReady: prevIsReady}) {
    const {activeSection, isReady} = this.state;

    if (isReady && ((activeSection !== prevActiveSection) || isReady !== prevIsReady)) {
      const section = this.props.sections[activeSection];
      if (section) {
        this.playFromTo(section.keyframes[0], section.keyframes[1]);
      }
    }
  }

  makeActive(section) {
    const {isPlaying} = this;

    if (isPlaying) {
      this.killUpdateListener();
    }

    this.setState({
      activeSection: section,
    });
  }

  playFromTo(from, to) {
    const {duration} = this.video;

    this.video.currentTime = duration * from;

    this.handleVideoUpdate = () => {
      if (this.video.currentTime >= duration * to) {
        this.killUpdateListener();
        this.video.pause();

        this.isPlaying = false
      }
    };

    this.video.addEventListener('timeupdate', this.handleVideoUpdate);
    this.video.play();

    this.isPlaying = true
  }

  killUpdateListener() {
    this.video.removeEventListener('timeupdate', this.handleVideoUpdate);
    this.handleVideoUpdate = null;
  }

  handleVideoReady(video) {
    this.video = video
    this.setState({
      isReady: true
    })
  }

  render() {
    const {video} = this.props;

    return (
      <div className="video-animation">
        <FullScreenContainer>
          <VideoPlayer onVideoLoadedMetaData={this.handleVideoReady} src={video} muted playsinline={true} />
        </FullScreenContainer>
      </div>
    );
  }
};


export default VideoAnimation;
