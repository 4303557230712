import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import MovieScene from 'components/MovieScene';
import scene from 'components/scene';
import Clip from './ch5-SCENE-07-Marching_For_The_Vote.mp4';

const Chapter5Scene4 = ({data}) => (
  <div className="chapter-4-scene-4">
    <MovieScene
      sceneNumber={7}
      sceneSubtitle={
        <span><span className="italic">SELMA</span> SCENE 7</span>
      }
      sceneDescription="Marching for the Vote"
      firstImage={data.ReadyForMarchStill}
      lastImage={data.ReadyForMarchStill}
      videoSrc={Clip}
    />
  </div>
);

const query = graphql`
  query {
    ReadyForMarchStill: file(relativePath: { eq: "chapter-5/scene-4/images/ch-5-image-readyforthemarch-still@2x.png" }) {
      ...largeImage
    }
  }
`;

const ChapterComponent = scene(Chapter5Scene4);
export default (props) => {
  const data = useStaticQuery(query);
  return <ChapterComponent data={data} {...props}/>;
};
