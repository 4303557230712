import React, { Component } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import './chapter-5-scene-9.scss';
import FullScreenContainer from 'components/FullScreenContainer';
import FullScreenImage from 'components/FullScreenImage';
import CalendarStrip from 'components/CalendarStrip';
import ScrollContainer from 'components/ScrollContainer';
import Timeline from 'components/timelineComponent';
import { Image } from 'components/Image';
import SplitView, { viewTypes } from 'templates/SplitView';
import {tabbable} from 'utils/accessibility'

import {
  placeholder, hideAllRefs, showElemWithAria, hideElementWithAria, makeElementTabbable, unmakeElementTabbable
} from 'utils/animation';
import scene from 'components/scene';
import {TimelineMarkers} from 'utils/scene'
import { removeTab, addTab } from '../../../../utils/animation';

const getCalendarStrip = () => (
  <CalendarStrip
    className="cal-strip"
    position={0}
    dates={[
      {
        number: 7,
        dateText: 'March to Montgomery',
        crossedOut: true
      },
      { number: 8 },
      {
        number: 9,
        dateText: 'March to Montgomery'
      },
      { number: 10 },
      { number: 11 },
      { number: 12 },
      { number: 13 }
    ]}
  />
);

class Chapter5Scene9 extends React.Component {
  render() {
    const { data } = this.props;
    const {
      AlabamaMap,
      Judge,
      Nuns,
      King,
      Police,
      Protesters,
      RiotRed,
      LbJThinking,
      MapCircle,
      CalendarCircle,
      JohnDoar,
      LeroyCollins,
      Standoff,
      KneelingMarchers,
      MarchersToBrownChapel
    } = data;

    return (
      <div className="chapter-5-scene-9">
        <div className="schedule" ref="schedule">
          <FullScreenImage
            image={AlabamaMap}
            aspectRatio={8 / 5}
            alt="Map of Selma and Montgomery">
            <ScrollContainer>
              <div className="schedule__content">
                <p {...tabbable} className="subhead" ref="scheduleTextTop">
                  As he prepares for the new march, King faces a dilemma:
                </p>
                <div className="calendar" ref="scheduleCalendar">
                  {getCalendarStrip()}
                </div>
                <p {...tabbable} className="subhead" ref="scheduleTextBottom">
                  He learns that federal Judge Frank Johnson wants SCLC to{' '}
                  <span className="highlight">postpone the Tuesday march</span>{' '}
                  until a full hearing can be held later that week.
                </p>
              </div>
            </ScrollContainer>
          </FullScreenImage>
        </div>

        <div className="judge" ref="judge">
          <SplitView
            leftPanel={
              <div className="judge__left">
                <h3 {...tabbable} ref="judgeTitle">
                  If SCLC does not postpone,
                  <br />
                  Judge Johnson will{' '}
                  <span className="highlight">ban the march</span>.
                </h3>
                <p {...tabbable} className="alt" ref="judgeParagraph">
                  If the march is canceled, King will let down organizers and
                  hundreds of allies who have gathered in Selma ready to march
                  for the right to vote.
                  <br />
                  <br />
                  If the march goes ahead, King will have defied a federal judge
                  for the first time.
                  <br />
                  <br /> He will also be{' '}
                  <span className="highlight">defying</span> the wishes of the
                  president and the attorney general—
                  <span className="highlight">
                    whose support is essential
                  </span>{' '}
                  to passing a Voting Rights Act.
                </p>
              </div>
            }
            rightPanel={
              <div className="judge__right">
                <Image image={Judge} alt="Frank M. Johnson Jr. is shown in a 1972 photo. He is a federal judge who issued key rulings that helped bring down racial barriers across the South and improved the treatment of prisoners and mental patients. (AP Photo)" showAria/>
              </div>
            }
          />
        </div>

        <div className="preparation" ref="preparation">
          <SplitView
            leftPanel={
              <div className="preparation__left">
                <Image image={Nuns} alt="Nuns are in the forefront of new civil rights march in Selma, Alabama. Selma City officials halted the marchers less than a block from their starting point and told them no march would be allowed." showAria/>
              </div>
            }
            rightPanel={
              <div className="preparation__right">
                <p {...tabbable} className="alt" ref="preparationParagraph1">
                  Some of King’s allies—especially his legal advisors—recommend
                  postponing the march and obeying Judge Johnson; they believe
                  he is a fair judge.
                </p>
                <h3 {...tabbable} ref="preparationTitle">
                  Others in SCLC and SNCC{' '}
                  <span className="highlight">urge King to go ahead</span>.
                </h3>
                <p {...tabbable} className="alt" ref="preparationParagraph2">
                  More than 2000 people from all over the US, including many
                  more whites than at previous marches, converge on Selma in
                  response to MLK’s call.
                </p>
              </div>
            }
          />
        </div>

        <div className="mlk" ref="mlk">
          <FullScreenContainer>
            <div className="mlk__bg">
              <Image image={King} alt="MLK"/>
            </div>
            <h3 {...tabbable} ref="mlkText">King has to decide to march or not.</h3>
          </FullScreenContainer>
        </div>

        <div className="lobby-judge" ref="lobbyJudge">
          <FullScreenContainer className="container">
            <div className="lobby-judge__left">
              <Image image={Protesters} alt="Protesters"/>
            </div>
            <div className="lobby-judge__middle">
              <h4 {...tabbable} ref="lobbyJudgeTitle">
                Behind the scenes, SCLC and NAACP-LDF lawyers lobby federal
                Judge Frank Johnson to:
              </h4>
              <div {...tabbable} className="lobby-paragraph" ref="lobbyJudgeText1">
                <p className="alt">1.</p>
                <p className="alt">Let the Tuesday march go ahead.</p>
              </div>
              <div {...tabbable} className="lobby-paragraph" ref="lobbyJudgeText2">
                <p className="alt">2.</p>
                <p className="alt">
                  Issue a <span className="highlight">restraining order</span>{' '}
                  to prevent state troopers repeating their attack on the
                  marchers.
                </p>
              </div>
            </div>
            <div className="lobby-judge__right">
              <Image image={Police} alt="police"/>
            </div>
          </FullScreenContainer>
        </div>

        <div className="lobby-lbj" ref="lobbyLbj">
          <SplitView
            viewType={viewTypes.ONE_THIRD}
            leftPanel={
              <div className="lobby-lbj__left">
                <p {...tabbable} className="subhead" ref="lobbyLbjTitle">
                  While LBJ supports plans to speed voting rights legislation in
                  Congress, he opposes a second march,{' '}
                  <span className="highlight">
                    fearing a repeat of Bloody Sunday
                  </span>
                  .
                </p>
                <p {...tabbable} className="alt" ref="lobbyLbjParagraph">
                  SCLC also fears a repeat of the violence—and possibly an
                  assassination attempt on King—so they urge LBJ to promise
                  federal protection for the marchers.
                </p>
              </div>
            }
            rightPanel={
              <div className="lobby-lbj__right">
                <div className="red-bg">
                  <Image image={RiotRed} alt="Riot"/>
                </div>
                <div className="lbj">
                  <Image image={LbJThinking} showAria alt="It's birthday time for the Chief Executive. On Saturday, August 27th, President Johnson will celebrate his 58th year at the LBJ Ranch in Texas. The Chief Executive is pictured in a recent photo at the White House and in honor of his birthday, the portrait is one of his favorites."/>
                </div>
              </div>
            }
          />
        </div>
        <div className="decision" ref="decision">
          <FullScreenImage
            image={AlabamaMap}
            aspectRatio={8 / 5}
            alt="Alabama Map">
            <div className="decision__container">
              <div className="decision__doodle doodle">
                <Image image={MapCircle} alt="Doodle circle"/>
              </div>
              <FullScreenContainer className="decision__content">
                <ScrollContainer>
                <div className="content">
                  <p className="subhead" ref="decisionText1">
                    On Monday, King agrees to postpone the march, and SCLC lawyers
                    inform Judge Johnson and Assistant Attorney General John Doar.
                  </p>
                  <div className="calendar" ref="decisionCalendar">
                    {getCalendarStrip()}
                    <div className="calendar-circle">
                      <div ref="decisionCalendarCircle">
                        <Image image={CalendarCircle} alt="Doodle circle"/>
                      </div>
                      <div className="postponed" ref="decisionCalendarPostponed">
                        <h3>POSTPONED</h3>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p {...tabbable} className="subhead" ref="decisionText2">
                      A few hours later, King changes his mind.
                    </p>
                    <p {...tabbable} className="alt" ref="decisionText3">
                      His conscience compels him to go ahead with the march, having
                      urged so many to come to Selma. He also believes that SNCC and
                      others will proceed even if he attempts to call it off.
                    </p>
                  </div>
                </div>
              </ScrollContainer>
              </FullScreenContainer>
            </div>
          </FullScreenImage>
        </div>

        <div className="negotiators" ref="negotiators">
          <SplitView
            fullWidthPanel={
              <FullScreenImage
                image={AlabamaMap}
                aspectRatio={8 / 5}
                alt="Alabama Map"/>
            }
            leftPanel={
              <div className="negotiators__left">
                <div className="doar" ref="negotiatorsDoar">
                  <Image image={JohnDoar} showAria alt="John Doar is shown at a Grenada, Miss., hearing in Oxford, Mississippi, Sept. 1966. (AP Photo)"/>
                </div>
                <div className="collins" ref="negotiatorsCollins">
                  <Image image={LeroyCollins} showAria alt="Gov. of Florida, Leroy Collins at his home. (Photo by Donald Uhrbrock/The LIFE Images Collection/Getty Images)"/>
                </div>
                <h4 className="doar-text" ref="negotiatorsDoarText">
                  John Doar
                </h4>
                <h4 className="collins-text" ref="negotiatorsCollinsText">
                  Leroy Collins
                </h4>
              </div>
            }
            rightPanel={
              <div className="negotiators__right">
                <p {...tabbable} className="alt" ref="negotiatorsText1">
                  On the morning of March 9, 1965, most of those gathered expect
                  the march to go ahead in defiance of Judge Johnson.
                </p>
                <p {...tabbable} className="subhead" ref="negotiatorsText2">
                  But unknown to them (and the press) LBJ attempts{' '}
                  <span className="highlight">to broker a compromise</span>.
                </p>
                <p {...tabbable} className="alt" ref="negotiatorsText3">
                  He sends a federal negotiator, Leroy Collins, to Selma to join
                  with John Doar to meet separately with King and with Sheriff
                  Clark and Col. Al Lingo, commander of the Alabama state
                  troopers.
                </p>
              </div>
            }
          />
        </div>

        <div className="compromise" ref="compromise">
          <SplitView
            fullWidthPanel={
              <div className="compromise__images">
                <FullScreenContainer>
                  <div ref="compromiseImage1" aria-label="View of Civil Rights marchers as they reach a police roadblock across a street outside of Selma, Alabama, during the second Selma to Montgomery Civil Rights march, also known as 'Turnaround Tuesday', Selma, Alabama, 9th March 1965. (Photo by Frank Dandridge/The LIFE Images Collection/Getty Images)" role='img'>
                    <FullScreenImage className="compr-img"
                      image={Standoff} aspectRatio={8/5}
                      alt=""
                    />
                  </div>
                  <div ref="compromiseImage2" role="img" aria-label="Civil rights marchers kneel to pray after crossing the Edmund Pettus Bridge in the second attempt to march to Montgomery. In the first march, civil rights activists were beaten by state and local police. The second attempt was turned around by police after the prayer. A policeman stands with his billy club ready. (Photo by © Flip Schulke/CORBIS/Corbis via Getty Images)">
                    <FullScreenImage className="compr-img" ref="compromiseImage2"
                      image={KneelingMarchers} aspectRatio={8/5}
                      alt="Kneeling Marchers"
                    />
                  </div>
                  <div ref="compromiseImage3" role="img" aria-label="Activity during the time of the Selma to Montgomery March, Alabama, 1965. (Photo by Declan Haun/Chicago History Museum/Getty Images)">
                    <FullScreenImage className="compr-img"
                      image={MarchersToBrownChapel} aspectRatio={8/5}
                      alt="Marchers to Brown Chapel"
                    />
                  </div>

                </FullScreenContainer>
              </div>
            }
            leftPanel={
              <div className="compromise__left">
                <p className="intro" ref="compromiseTitle">
                  Collins’ and Doar’s
                  <br />
                  compromise is this:
                </p>
                <div className="demands-list">
                  <div className="demand">
                    <h1 className="highlight" ref="compromiseN1">
                      1
                    </h1>
                    <p className="alt" ref="compromiseText1">
                      King will begin the march but stop in front of the line of
                      waiting state troopers.
                    </p>
                  </div>
                  <div className="demand">
                    <h1  className="highlight" ref="compromiseN2">
                      2
                    </h1>
                    <p className="alt" ref="compromiseText2">
                      King will make a symbolic gesture of protest. He will then
                      turn the march around in compliance with Judge Johnson’s
                      order.
                    </p>
                  </div>
                  <div className="demand" >
                    <h1 className="highlight" ref="compromiseN3">
                      3
                    </h1>
                    <p className="alt" ref="compromiseText3">
                      If King does this, Clark and Lingo will promise not to
                      arrest or attack the protesters and will allow them to
                      return to Brown Chapel.
                    </p>
                  </div>
                </div>
              </div>
            }
          />
        </div>
      </div>
    );
  }
}

const animation = ({context, timeline, easeOut, easeIn}) => {

  const {
    schedule, scheduleTextTop, scheduleCalendar, scheduleTextBottom,
    judge, judgeTitle, judgeParagraph,
    preparation, preparationParagraph1, preparationTitle, preparationParagraph2,
    mlk, mlkText,
    lobbyJudge, lobbyJudgeTitle, lobbyJudgeText1, lobbyJudgeText2,
    lobbyLbj, lobbyLbjTitle, lobbyLbjParagraph,
    decision, decisionText1, decisionCalendar, decisionCalendarCircle,
    decisionCalendarPostponed, decisionText2, decisionText3,
    negotiators, negotiatorsDoar, negotiatorsCollins, negotiatorsDoarText, negotiatorsCollinsText,
    negotiatorsText1, negotiatorsText2, negotiatorsText3, compromise1, compromise2, compromise3,
    compromise, compromiseImage1, compromiseImage2, compromiseImage3, compromiseTitle, compromiseN1, compromiseText1,
    compromiseN2, compromiseText2, compromiseN3, compromiseText3, Standoff,
  } = context.refs;

  hideAllRefs(context.refs, ['schedule']);


    timeline.fromTo(
      scheduleTextTop,
      1.5,
      { y: '+=50' },
      { y: '-=50', ...showElemWithAria, ...easeOut }
    );
      timeline.fromTo(
        scheduleCalendar,
        1.5,
        { y: '+=50' },
        { y: '-=50', ...showElemWithAria, ...easeOut }, "-=1.25"
      );
  timeline.fromTo(
    scheduleTextBottom,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut }, "-=1.25"
  );

  timeline.markers.createTimelineMarker();

  timeline.to(schedule, 0.5, { ...hideElementWithAria });
  timeline.to(judge, 0.5, { ...showElemWithAria }, '-=0.5');
  timeline.fromTo(judgeTitle, 1.5, { y: '+=50' }, { y: '-=50', ...showElemWithAria, ...easeOut });
  timeline.fromTo(
    judgeParagraph,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut }, "-=1.25"
  );

  timeline.markers.createTimelineMarker();

  timeline.to(judge, 0.5, { ...hideElementWithAria });
  timeline.to(preparation, 0.5, { ...showElemWithAria }, '-=0.5');
  timeline.fromTo(
    preparationParagraph1,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut }
  );
  timeline.fromTo(
    preparationTitle,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut }, "-=1.25"
  );
  timeline.fromTo(
    preparationParagraph2,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut }, "-=1.25"
  );

  timeline.markers.createTimelineMarker()

  timeline.to(preparation, 1, {...hideElementWithAria});
  timeline.to(mlk, 1, {...showElemWithAria}, '-=1');
  timeline.fromTo(mlkText, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut }
  );

  timeline.markers.createTimelineMarker();

  timeline.to(mlk, 1, { ...hideElementWithAria });
  timeline.to(lobbyJudge, 1, { ...showElemWithAria }, '-=1');
  // timeline.fromTo(
  //   lobbyJudgeLeft,
  //   1.5,
  //   { x: '-=50' },
  //   { x: '+=50', ...showElemWithAria, ...easeOut }
  // );
  // timeline.fromTo(
  //   lobbyJudgeRight,
  //   1.5,
  //   { x: '+=50' },
  //   { x: '-=50', ...showElemWithAria, ...easeOut }, "-=1.25"
  // );
  timeline.fromTo(
    lobbyJudgeTitle,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut }, "-=0.5"
  );
  timeline.fromTo(
    lobbyJudgeText1,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut }, "-=1.25"
  );
  timeline.fromTo(
    lobbyJudgeText2,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut }, "-=1.25"
  );

  timeline.markers.createTimelineMarker();

  timeline.to(lobbyJudge, 1, { ...hideElementWithAria });
  timeline.to(lobbyLbj, 1, { ...showElemWithAria }, '-=1');
  timeline.fromTo(
    lobbyLbjTitle,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut }
  );
  timeline.fromTo(
    lobbyLbjParagraph,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut }, "-=1.25"
  );

  timeline.markers.createTimelineMarker();

  timeline.to(lobbyLbj, 1, { ...hideElementWithAria });
  timeline.to(decision, 1, { ...showElemWithAria }, '-=0.5');

  // timeline.to(decisionDoodle, 0.5, { ...showElemWithAria });
  timeline.to(decisionText1, 0, {...makeElementTabbable})
    timeline.fromTo(
      decisionText1,
      1.5,
      { y: '+=50' },
      { y: '-=50', ...showElemWithAria, ...easeOut }, "-=0.5"
    );
      timeline.fromTo(
        decisionCalendar,
        1.5,
        { y: '+=50' },
        { y: '-=50', ...showElemWithAria, ...easeOut }, "-=1.25"
      );

  timeline.to(decisionCalendarPostponed, 0.5, { ...showElemWithAria }, '-=0.5');

  timeline.markers.createTimelineMarker();

  timeline.to(decisionText1, 0.5, { autoAlpha: 0.2, ...unmakeElementTabbable });
  timeline.to(decisionCalendarPostponed, 0.5, { ...hideElementWithAria });
  timeline.to(decisionCalendarCircle, 0.5, { ...showElemWithAria }, '-=0.5');
  // placeholder(timeline, 2);
  timeline.to(decisionCalendar, 0.5, { className: '+=march' }, '-=0.5');
  timeline.fromTo(
    decisionText2,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut }, "-=0.5"
  );
  timeline.fromTo(
    decisionText3,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut }, "-=1.25"
  );

  timeline.markers.createTimelineMarker();

  timeline.to(decision, 0.5, { ...hideElementWithAria });
  timeline.to(negotiators, 0.5, { ...showElemWithAria }, '-=0.5');
  timeline.fromTo(
    [negotiatorsDoar, negotiatorsDoarText],
    1.5,
    { x: '+=50' },
    { x: '-=50', ...showElemWithAria, ...easeOut}
  );
  timeline.fromTo(
    [negotiatorsCollins, negotiatorsCollinsText],
    1.5,
    { x: '+=50' },
    { x: '-=50', ...showElemWithAria, ...easeOut}, "-=1.25"
  );

  timeline.fromTo(
    negotiatorsText1,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut}, "-=1.25"
  );
  timeline.fromTo(
    negotiatorsText2,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut}, "-=1.25"
  );
  timeline.fromTo(
    negotiatorsText3,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut}, "-=1.25"
  );

  timeline.markers.createTimelineMarker();

  timeline.to(negotiators, 0.5, { ...hideElementWithAria });
  timeline.to(compromise, 1, { ...showElemWithAria }, '-=0.5');
  timeline.to([compromiseTitle, compromiseN1, compromiseText1], 0, {...makeElementTabbable})
  timeline.fromTo(
    compromiseTitle,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut}, "-=0.5"
  );

  timeline.to(
    compromiseN1,
    0.5,
    { ...showElemWithAria, ease: Linear.easeNone}
  );
  timeline.fromTo(
    compromiseText1,
    1.5,
    { x: '+=50' },
    { x: '-=50', ...showElemWithAria, ...easeOut}, "-=0.5"
  );

  TweenMax.set(compromiseImage1, {...hideElementWithAria})
  timeline.to(compromiseImage1, 1.5, {...showElemWithAria, ...addTab}, "-=1.5")

  timeline.markers.createTimelineMarker()

  timeline.to(compromiseN1, 0.5, { className: '-=highlight' });
  timeline.to(compromiseN2, 0.5, { ...showElemWithAria, ease: Linear.easeNone }, "-=0.5");
  timeline.to([compromiseTitle, compromiseN1, compromiseText1], 0, {...unmakeElementTabbable}, "-=0.49")
  timeline.to([compromiseN2, compromiseText2], 0, {...makeElementTabbable}, "-=0.49")

  timeline.fromTo(
    compromiseText2,
    1.5,
    { x: '+=20' },
    { x: '-=20', ...showElemWithAria, ...easeOut}, "-=0.5"
  );
  TweenMax.set(compromiseImage2, {...hideElementWithAria})
  timeline.to(compromiseImage2, 1.5, {...showElemWithAria, ...addTab}, "-=1.5")
  timeline.to(compromiseImage1, 0, { ...removeTab})
  timeline.markers.createTimelineMarker();

  timeline.to(compromiseN2, 0.5, { className: '-=highlight' });
  timeline.to(compromiseN3, 0.5, { ...showElemWithAria, ease: Linear.easeNone }, "-=0.5");

  timeline.to([compromiseN2, compromiseText2], 0, {...unmakeElementTabbable}, "-=0.49")
  timeline.to([compromiseN3, compromiseText3], 0, {...makeElementTabbable}, "-=0.49")

  timeline.fromTo(
    compromiseText3,
    1.5,
    { x: '+=20' },
    { x: '-=20', ...showElemWithAria, ...easeOut}, "-=0.5"
  );
  TweenMax.set(compromiseImage3, {...hideElementWithAria})
  
  timeline.to(compromiseImage3, 1.5, {...showElemWithAria, ...addTab}, "-=1.5")
  timeline.to(compromiseImage2, 0, { ...removeTab})
  timeline.markers.createTimelineMarker();

};

const query = graphql`
  query {
    AlabamaMap: file(
      relativePath: {
        eq: "chapter-5/scene-9/images/ch-5-image-alabama-mapbg@2x.png"
      }
    ) {
      ...largeImage
    }

    Judge: file(
      relativePath: { eq: "chapter-5/scene-9/images/ch-5-image-mlk-lbj@2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    Nuns: file(
      relativePath: {
        eq: "chapter-5/scene-9/images/ch-5-image-nuns-march-sepia@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    King: file(
      relativePath: { eq: "chapter-5/scene-9/images/ch-5-image-king-bw@2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2074) {
          ...selmaFluidImage
        }
      }
    }

    Police: file(
      relativePath: {
        eq: "chapter-5/scene-9/images/ch-5-image-police-lineup.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...selmaFluidImage
        }
      }
    }

    Protesters: file(
      relativePath: {
        eq: "chapter-5/scene-9/images/ch-5-image-protesters-lineup.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...selmaFluidImage
        }
      }
    }

    RiotRed: file(
      relativePath: {
        eq: "chapter-5/scene-9/images/ch-5-image-police-riot-red@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1848) {
          ...selmaFluidImage
        }
      }
    }

    LbJThinking: file(
      relativePath: {
        eq: "chapter-5/scene-9/images/ch-5-image-lbj-thinking@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 944) {
          ...selmaFluidImage
        }
      }
    }

    MapCircle: file(
      relativePath: {
        eq: "chapter-5/scene-9/images/ch-5-doodle-mapcircle-and-x-grey@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 2036) {
          ...selmaFluidImage
        }
      }
    }

    CalendarCircle: file(
      relativePath: {
        eq: "chapter-5/scene-9/images/ch-5-doodle-calendar-circle@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 492) {
          ...selmaFluidImage
        }
      }
    }

    JohnDoar: file(
      relativePath: {
        eq: "chapter-5/scene-9/images/ch-5-image-john-doar@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 844) {
          ...selmaFluidImage
        }
      }
    }

    LeroyCollins: file(
      relativePath: {
        eq: "chapter-5/scene-9/images/ch-5-image-leroy-collins@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1078) {
          ...selmaFluidImage
        }
      }
    }

    Standoff: file(
      relativePath: {
        eq: "chapter-5/scene-9/images/ch-5-image-compromise-standoff@2x.png"
      }
    ) {
      ...largeImage
    }

    KneelingMarchers: file(
      relativePath: {
        eq: "chapter-5/scene-9/images/ch-5-image-kneeling-marchers@2x.png"
      }
    ) {
      ...largeImage
    }

    MarchersToBrownChapel: file(
      relativePath: {
        eq: "chapter-5/scene-9/images/ch-5-image-marchers-to-brown-chapel@2x.png"
      }
    ) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(
  Timeline(Chapter5Scene9, { animation })
);
export default props => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
