export const Chapter5Qa = [
  {
    questionNumber: '5.01',
    question: 'What considerations does MLK make when he agrees not to lead the march?',
    answer: `King considers safety, his own and that of others. He knows the troopers have been ordered to block the march using any means necessary. He also considers how the troopers blocking the march may mobilize national support for the movement. He knows the march is illegal without federal support, but he knows local enthusiasm is very strong.`,
  },
  {
    questionNumber: '5.02',
    question: 'How was the march strengthened/weakened by King’s absence?',
    answer: `Answers will vary.`,
  },
  {
    questionNumber: '5.03',
    question: `What do Lewis and Forman think about participating in Sunday's march?`,
    answer: `They, like some members of SNCC, are in disagreement with SCLC approach and oppose the march. However, Lewis wants to participate, largely due to local enthusiasm and to honor Jimmie Lee Jackson.`,
  },
  {
    questionNumber: '5.04',
    question: `What challenges does Lewis face when deciding to participate in Sunday's march?`,
    answer: `Lewis faces conflict within SNCC. He risks his relationship with other members of SNCC.`,
  },
  {
    questionNumber: '5.05',
    question: 'Who comes to Selma from across the U.S and why?',
    answer: `Clergy from across the country come to Selma, AL to participate in a second march because King asked for national support after Bloody Sunday.`,
  },
  {
    questionNumber: '5.06',
    question: 'How does the arrival of the "outsiders"—black and white—make a difference to how LBJ and others view the Selma protests?',
    answer: `Answers will vary and may include: LBJ knows the voting rights movement has gained national attention and can no longer be ignored.`,
  },
  {
    questionNumber: '5.07',
    question: 'How were these allies critical to the movement’s success?',
    answer: `Answers will vary`,
  },
  {
    questionNumber: '5.08',
    question: 'What are the consequences of MLK accepting LBJ/Doar offer?',
    answer: `King risks interrupting the momentum of the movement. He also risks losing the trust of the people he is leading.`,
  },
  {
    questionNumber: '5.09',
    question: 'What are the risks involved in obeying Judge Johnson? What are the risks involved in disobeying Judge Johnson?',
    answer: `Answers will vary and may include: Disobeying the order means defying a federal order, which King had never done before. Obeying means losing the trust of those in the movement in Selma.`,
  },
  {
    questionNumber: '5.10',
    question: 'Why are SNCC members angry at MLK on Turnaround Tuesday?',
    answer: `Some SNCC members feel King is giving in to federal and state government.`,
  },
  {
    questionNumber: '5.11',
    question: 'What message does MLK’s action send to LBJ/Judge Johnson?',
    answer: `King sends the message that he wants to work together with LBJ to secure passage of a voting rights act.`,
  },
];
