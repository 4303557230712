import React, { Component } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { TweenMax } from 'gsap';

import SplitView from 'templates/SplitView';
import Timeline from 'components/timelineComponent';
import ImageSwap from 'components/ImageSwap';
import ImageSwapAngle from 'components/ImageSwapAngle';
import FullScreenContainer from 'components/FullScreenContainer';
import { Image } from 'components/Image';
import Modal from 'components/ChapterModal';
import {tabbable} from 'utils/accessibility'
import {
  stickyPositions,
  modalWindowBackgrounds
} from 'components/ModalContent';
import TextMask from 'components/TextMask';
import ActorPortrait from 'components/ActorPortrait';

import {
  placeholder, hideAllRefs, showElemWithAria, hideElementWithAria, createDarkThemeOnStartSetter, createLightThemeOnStartSetter, makeElementTabbable, unmakeElementTabbable
} from 'utils/animation';
import { getPercentageBetweenBounds } from 'utils';

import './chapter-5-scene-1.scss';
import JaggedLine from './images/ch-5-image-jagged-line.svg';

import scene from 'components/scene';
import { TimelineMarkers } from 'utils/scene';
import { addTab, removeTab } from '../../../../utils/animation';

// value retrieved from logging out the timeline's duration value to the console
const animationDuration = 35.1

// numerators retrieved from logging out the timeline's duration at the point
// at which we want these values to start/end
const imageSwapStart = 5.1 / animationDuration;
const imageSwapEnd = 6.6 / animationDuration

const textMaskStart = 11.6
const textMaskEnd = textMaskStart + 3

const deputiesImageSwapStart = 19.1 / animationDuration;
const deputiesImageSwapEnd = 21.1 / animationDuration;

class C5S1 extends React.Component {
  render() {
    const { position, data } = this.props;

    const {
      BrickWall,
      RestRoomSign,
      CtvFg,
      CtvBg,
      BillOfRights,
      JamesOrangeBg,
      JamesOrangePortrait,
      OmarDorsey,
      JimmyLeeJackson,
      JlJdead,
      JlJFuneralOutside,
      JlJFuneralInside,
      PerryCountyJail,
      Singers,
      ArrowDown,
      Bevel,
      BevelArrow,
      persistBg,
      persistFg,
      DeputiesFg,
      DeputiesBg
    } = data;

    const imageSwapProgress = getPercentageBetweenBounds(
      position,
      imageSwapStart,
      imageSwapEnd
    );

    const deputiesImageSwapProgress = getPercentageBetweenBounds(
      position,
      deputiesImageSwapStart,
      deputiesImageSwapEnd
    );

    return (
      <div className="chapter-5-scene-1">
        <div className="segregation-sign" ref="segregation">
          <FullScreenContainer>
            <div className="segregation-sign__wall">
              <Image image={BrickWall} alt="Brick wall" />
            </div>

            <div className="segregation-sign__sign" ref="segregationSign">
              <Image image={RestRoomSign} alt="Sign for segregated bathroom" />
            </div>

            <div className="segregation-sign__info" ref="segregationInfo">
              <div className="segregation-sign__info__text">
                <h3 {...tabbable} ref="segregationInfoText">
                  By the end of 1964, Selma’s businesses and local government had
                  finally removed the official “Colored” and “White” signs of
                  segregation in compliance with the 1964 Civil Rights Act.
                </h3>
              </div>
            </div>
          </FullScreenContainer>
        </div>

        <div className="voting-rights" ref="votingRights">
          <SplitView
            fullWidthPanel={
              <div ref="votingRightsBg">
                <ImageSwap
                  position={imageSwapProgress}
                  image={CtvFg}
                  image2={CtvBg}
                  showAria
                  alt="Dallas County Sheriff Jim Clark grabs C. T. Vivian and denies him access to the registrar's office here. Vivian and his followers were then placed under arrest when they refused to leave."
                />
                <div className="voting-rights__overlay">
                  <div
                    className="name-doodle reverend"
                    ref="votingRightsReverend">
                    <h5>Reverend C.T. Vivian</h5>
                    <div className="arrow">
                      <Image image={ArrowDown} alt="Arrow Down" />
                    </div>
                  </div>
                  <div className="name-doodle clark" ref="votingRightsClark">
                    <h5>Jim Clark</h5>
                    <div className="arrow">
                      <Image image={ArrowDown} alt="Arrow Down" />
                    </div>
                  </div>
                </div>
              </div>
            }
            rightPanel={
              <div ref="votingRightsText1" className="voting-rights__right">
                <p className="subhead" ref="votingRightsHead">
                  But voting rights efforts in Selma are stalled because of an
                  order by segregationist Alabama Circuit Judge, James Hare.
                </p>
                <p className="alt" ref="votingRightsBody">
                  Hare’s injunction bans three or more members of the DCVL,
                  SNCC, SCLC, and the NAACP from holding public meetings: if
                  they do so, Dallas County Sheriff Jim Clark is empowered to
                  arrest them.
                </p>
              </div>
            }
          />
        </div>

        <div className="bill-of-rights" ref="billOfRights">
          <SplitView
            leftPanel={
              <div className="bill-of-rights__left">
                <div className="bor-bg" ref="billOfRightsBg">
                  <Image image={BillOfRights} alt="Bill of Rights" />
                </div>
                <p {...tabbable} className="subhead" ref="billOfRightsText">
                  The ban is meant to{' '}
                  <span className="highlight">destroy the momentum</span>
                  <br />
                  of the voting rights campaign.
                  <br />
                  <br />
                  It <span className="highlight">denies</span> activists their
                  First Amendment right to{' '}
                  <span className="highlight">free speech</span> and{' '}
                  <span className="highlight">assembly</span>.
                </p>
                <div className="jagged-line" ref="billOfRightsLine">
                  <JaggedLine />
                </div>
              </div>
            }
          />
        </div>

        <div className="persist" ref="persist">
          <TextMask
            className="mask"
            backgroundImage={persistBg}
            maskImage={persistFg.childImageSharp.fluid.src}
            text="NEVERTHELESS, THEY PERSIST"
            position={getPercentageBetweenBounds(position, (textMaskStart/animationDuration), (textMaskEnd/animationDuration))}
          />
        </div>

        <div className="james-orange" ref="jamesOrange">
          <FullScreenContainer>
            <div className="james-orange__bg">
              <Image image={JamesOrangeBg} alt="James Orange Background" />
            </div>
            <div className="james-orange__content">
              <div className="portrait">
                <div
                  className="portrait__img jo-img" ref="jamesOrangeImg" role='img' aria-label="Rev. James Orange, SCLC field organizer." role='img'>
                  <Image
                    image={JamesOrangePortrait}
                    alt="James Orange Portrait"
                    caption="Bob Fitch Photography Archive, Department of Special Collections, Stanford University Libraries"
                  />
                </div>
                <div className="portrait__text">
                  <h4>James Orange</h4>
                </div>
              </div>
              <div {...tabbable} className="text-content">
                <p className="alt" ref="jamesOrangeTextMain">
                  In neighboring Marion, Alabama, Rev. James Orange of SCLC is
                  arrested for “contributing to the delinquency of minors” by
                  encouraging 700 students to march and sing freedom songs
                  outside the Perry County courthouse.
                </p>
                <p className="subhead" ref="jamesOrangeTextParagraph">
                  Community members, SNCC and SCLC activists plan a short nighttime march to serenade Orange in jail.
                </p>
              </div>
              <div className="jail">
                <div className="jail__text">
                  <h4>Perry County Jail</h4>
                </div>

                <div className="jail-img jo-img" ref="perryCountyJailImg" role='img' aria-label="B2B 20:59 - (Perry County Jail)">
                  <Image image={PerryCountyJail} alt="B2B 20:59 - (Perry County Jail)" />
                </div>
                <div
                  className="singers-img jo-img" role='img' aria-label="USA. Selma, Alabama. October 1964. The Freedom Choir in the Tabernacle Baptist Church. High school students and children helped start the Selma movement." ref="singersImg">
                  <Image image={Singers} alt="Singers" />
                </div>
              </div>
            </div>
          </FullScreenContainer>
        </div>

        <div className="police-attack" ref="policeAttack">
          <FullScreenContainer>
            <div className="police-attack__content">
              <div className="troopers">
                <ImageSwapAngle
                  position={deputiesImageSwapProgress}
                  image={DeputiesBg}
                  image2={DeputiesFg}
                  alt=""
                  alt2="A policeman stands guard outside a fence surrounding the Perry County Jail in Marion, Alabama on Feb. 4, 1965, as arrested civil rights demonstrators chant and sing freedom songs. About 300 persons were arrested after they marched on the courthouse and then refused to disperse. (AP Photo/Horace Cort)"
                  showAria2
                  offsetLeft={25}
                />
              </div>
              <div className="jimmie">
                <p className="subhead" ref="policeAttackSubhead">
                  At the night-time march, armed state troopers, Marion police,
                  and Perry County deputies attack protesters with clubs and
                  nightsticks.
                </p>
                <div className="portrait" ref="policeAttackPortrait" role='img' aria-label="Jimmie Lee Jackson">
                  <Image image={JimmyLeeJackson} alt="Jimmie Lee Jackson" />
                </div>
                <p className="alt credit" ref="policeAttackCredit">
                  *Courtesy of the Jackson family
                </p>

                <p className="alt" ref="policeAttackParagraph">
                  Marion native, Jimmie Lee Jackson, his mother, and his
                  82-year-old grandfather seek refuge in a café. When troopers
                  begin beating his mother, Viola Lee Jackson, Jimmie Lee
                  attempts to protect her.
                </p>
                <br />
                <p className="alt" ref="policeAttackParagraph2">
                  A trooper shoots him twice at point blank range.
                </p>
              </div>
            </div>
          </FullScreenContainer>
        </div>

        <div className="jlj-dead" ref="jljDead">
          <FullScreenContainer>
            <div className="jlj-dead__image" ref="jljDeadImg" role="img" aria-label="The body of Jimmie Lee Jackson, 26, is removed from a Selma, Alabama hospital Feb. 26, 1965 after he died of a gunshot wound inflicted during race violence in Marion, Alabama, Feb. 18, 1965. The violence broke out during a night demonstration of people against voter registration laws. (AP Photo)">
              <Image image={JlJdead} alt="" />
            </div>
            <div className="jlj-dead__white" ref="jljDeadWhite" />
            <div {...tabbable} className="jlj-dead__text" ref="jljDeadText">
              <p className="subhead">He dies 8 days later.</p>
            </div>
          </FullScreenContainer>
        </div>

        <div className="reactions" ref="reactions">
          <div className="coverage" ref="reactionsCoverage">
            <SplitView
              leftPanel={
                <div className="reaction-img" ref="reactionsCoverageImg" role='img' aria-label="‘Racism Killed Our Brother' reads a sign hung in front of church as pallbearers carry the casket of Jimmie Lee Jackson, Marion, Ala., into church here 3/3, for memorial services. Jackson was shot in racial demonstration in Marion 2/24, and claimed he was shot by policeman.">
                  <Image
                    image={JlJFuneralOutside}
                    alt=""
                  />
                </div>
              }
              rightPanel={
                <div {...tabbable} className="coverage__right">
                  <h3 ref="reactionsCoverageTitle">
                    His death receives little coverage in the national media,
                    and President Johnson does not publicly respond to it.
                  </h3>
                  <p className="alt" ref="reactionsCoverageParagraph">
                    However, it angers and rouses African Americans, especially
                    those active in the Selma movement.
                  </p>
                </div>
              }
            />
          </div>
          <div className="march" ref="reactionsMarch">
            <SplitView
              leftPanel={
                <div className="march__left">
                  <div className="reaction-img" ref="reactionsMarchImg">
                    <Image
                      image={JlJFuneralInside}
                      alt="Jimmie Lee Jackson Funeral Outside"
                    />
                  </div>

                  <div className="bevel">
                    <div className="bevel__img" ref="reactionsBevelImg" role='img' aria-label="Civil Rights activist, Reverend James Bevel in Selma, Alabama. Bevel proposed and organized the Selma to Montgomery March that would draw national attention to the civil rights movement in Alabama. (Photo by Steve Schapiro/Corbis via Getty Images)">
                      <Image image={Bevel} alt="" />
                    </div>
                    <div className="bevel__text" ref="reactionsBevelText">
                      <p className="caption big">James Bevel</p>
                      <p className="caption">SCLC Leader</p>
                    </div>
                    <div className="bevel__arrow" ref="reactionsBevelArrow">
                      <Image image={BevelArrow} alt="Arrow" />
                    </div>
                  </div>
                </div>
              }
              rightPanel={
                <div {...tabbable} className="march__right">
                  <p className="alt" ref="reactionsMarchParagraph">
                    Inspired by SCLC leader, James Bevel (who wanted to lay
                    Jimmie Lee’s body on the Capitol steps to show Governor
                    George Wallace what he had done), King denounces police
                    brutality at Jackson’s funeral—
                  </p>
                  <h3 ref="reactionsMarchTitle">
                    and announces a 54-mile march from Selma to the State
                    Capitol, in Montgomery.
                  </h3>
                </div>
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

const animation = ({context, timeline, sceneId, setTheme, easeIn, easeOut}) => {


  const {
    segregation,
    segregationSign,
    segregationInfo,
    segregationInfoText,
    votingRights,
    votingRightsBg,
    votingRightsHead,
    votingRightsBody,
    votingRightsReverend,
    votingRightsClark,
    votingRightsText1,
    billOfRights,
    billOfRightsBg,
    billOfRightsText,
    billOfRightsLine,
    persist,
    jamesOrange,
    jamesOrangeTextMain,
    jamesOrangeTextParagraph,
    policeAttack,
    policeAttackSubhead,
    policeAttackPortrait,
    policeAttackCredit,
    policeAttackParagraph,
    policeAttackParagraph2,
    jljDead,
    jljDeadImg,
    jljDeadWhite,
    jljDeadText,
    reactions,
    reactionsMarch,
    reactionsCoverage,
    reactionsCoverageImg,
    reactionsCoverageTitle,
    reactionsCoverageParagraph,
    reactionsMarchImg,
    reactionsMarchParagraph,
    reactionsMarchTitle,
    reactionsBevelImg,
    reactionsBevelText,
    reactionsBevelArrow,
    jamesOrangeImg,
    perryCountyJailImg,
    singersImg
  } = context.refs;

  const setDarkOnStart = createDarkThemeOnStartSetter(setTheme)
  const setLightOnStart = createLightThemeOnStartSetter(setTheme)

  hideAllRefs(context.refs, [
    'segregation',
    'segregationSign',
    'votingRightsText1',
    'votingRightsBg',
    'segregationInfoText',
    'billOfRights',
    'jljDeadWhite',
    'reactionsCoverage',
    'reactionsCoverageImg',
    'perryCountyJailImg',
    'singersImg',
    'jamesOrangeImg',
    'jljDeadImg'
  ]);
  TweenMax.set(billOfRights, { x: window.innerWidth });
  TweenMax.set(segregationInfoText, { y: 100, ...hideElementWithAria });

  placeholder(timeline, 1.25)

  // timeline.to(segregationSign, 0.1, { rotation: '2deg', ease: Quad.easeIn });
  // placeholder(timeline, 0.5)
  timeline.to(segregationSign, 0.25, { rotation: '45deg', ease: Quad.easeIn });
  timeline.to(segregationSign, 0.4, { rotation: '85deg', ease: Quad.easeOut });
  timeline.to(segregationSign, 0.4, { rotation: '70deg', ease: Quad.easeInOut });
  // timeline.to(segregationSign, 0.25, { rotation: '15deg', ease: Quad.easeOut });

  timeline.to(segregationSign, 0.5, { x: "-=100", y: `+=${window.innerHeight}`, ease: Quad.easeIn }, "-=0.2");
  timeline.to(segregationInfo, 0.5, { ...showElemWithAria });
  timeline.to(segregationInfoText, 1.5, { y: 0, ...showElemWithAria, ...easeOut }, "-=0.5");

  timeline.markers.createTimelineMarker();

  timeline.to(votingRights, 1, { ...showElemWithAria });
  timeline.to(votingRightsText1, 0, {...makeElementTabbable})
  timeline.set(segregation, { ...hideElementWithAria });

  timeline.fromTo(
    votingRightsClark,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut },
  );
  timeline.fromTo(
    votingRightsReverend,
    1.5,
    { y: '+=50' },
    { y: '-=50', ...showElemWithAria, ...easeOut },
    '-=1.5'
  );

  timeline.fromTo(
    votingRightsHead,
    1.5,
    { y: '+=20' },
    { y: '-=20', ...showElemWithAria, ...easeOut }, "-=1.25"
  );
  timeline.fromTo(
    votingRightsBody,
    1.5,
    { y: '+=20' },
    { y: '-=20', ...showElemWithAria, ...easeOut },
    '-=1.25'
  );

  timeline.markers.createTimelineMarker();

  timeline.to(votingRights, 2, { x: `-=${window.innerWidth / 2}`, ease: Expo.easeInOut });
  timeline.to(votingRightsText1, 0, {...unmakeElementTabbable}, "-=1.99")
  timeline.to(votingRightsBg, 1, { ...hideElementWithAria }, '-=2');
  timeline.to(billOfRights, 2, { x: `-=${window.innerWidth / 2}`, ease: Expo.easeInOut }, '-=2');
  timeline.to(votingRightsHead, 0.5, { autoAlpha: 0.5 }, '-=2');
  timeline.to(votingRightsBody, 0.5, { autoAlpha: 0.5 },'-=2');
  timeline.to(votingRightsClark, 0.5, { ...hideElementWithAria },'-=2');
  timeline.to(votingRightsReverend, 0.5, { ...hideElementWithAria },'-=2');
  timeline.to(billOfRightsLine, 0.5, { ...showElemWithAria },'-=1');
  timeline.fromTo(
    billOfRightsBg,
    1.5,
    { x: '+=50' },
    { x: '-=50', ...showElemWithAria, ...easeOut },
    '-=1'
  );
  timeline.fromTo(
    billOfRightsText,
    1.5,
    { y: '+=20' },
    { y: '-=20', ...showElemWithAria, ...easeOut },
    "-=0.5"
  );

  // placeholder(timeline, 0.5);
  timeline.markers.createTimelineMarker();
  timeline.to(votingRights, 1, {...hideElementWithAria})
  timeline.to(billOfRights, 1, {
    ...hideElementWithAria,
    ...setDarkOnStart
  }, "-=1");
  timeline.to(votingRightsHead, 1, { ...hideElementWithAria }, '-=1');
  timeline.to(votingRightsBody, 1, {...hideElementWithAria}, '-=1');
  timeline.to(persist, 1, { ...showElemWithAria }, '-=1');

  placeholder(timeline, 3);

  timeline.to(persist, 1, {
    ...hideElementWithAria,
    ...setLightOnStart
  });
  timeline.to(jamesOrange, 1, { ...showElemWithAria });
  timeline.to([perryCountyJailImg, jamesOrangeImg, singersImg], 0.01, { ...showElemWithAria, ...addTab });

  timeline.fromTo(
    jamesOrangeTextMain,
    1.5,
    { y: "+=50" },
    { y: "-=50", ...showElemWithAria, ...easeOut },
    '-=0.5'
  );
  timeline.fromTo(
    jamesOrangeTextParagraph,
    1.5,
    { y: "+=50" },
    { y: "-=50", ...showElemWithAria, ...easeOut },
    '-=1.25'
  );

  timeline.markers.createTimelineMarker();

  timeline.to(jamesOrange, 1, { ...hideElementWithAria });
  timeline.to([jamesOrangeImg, perryCountyJailImg, singersImg], 0.01, { ...hideElementWithAria, ...removeTab });
  timeline.to(policeAttack, 1, { ...showElemWithAria }, '-=1');
  // timeline.to(policeAttackTroopers, 0.5, {...showElemWithAria});
  timeline.to([
    policeAttackSubhead,
    policeAttackCredit,
    policeAttackParagraph
  ], 0, {...makeElementTabbable})
  timeline.fromTo(
    policeAttackSubhead,
    1.5,
    { y: '+=100' },
    { ...showElemWithAria, y: '-=100', ...easeOut }
  );
  timeline.fromTo(
    policeAttackPortrait,
    1.5,
    { y: '+=100' },
    { ...showElemWithAria, ...addTab, y: '-=100', ...easeOut },
    "-=1.25"
  );
  timeline.fromTo(
    policeAttackCredit,
    1.5,
    { y: '+=100' },
    { ...showElemWithAria, y: '-=100', ...easeOut }, "-=1.5"
  );
  timeline.fromTo(
    policeAttackParagraph,
    1.5,
    { y: '+=100' },
    { ...showElemWithAria, y: '-=100', ...easeOut },
    "-=1.25"
  );
  // timeline.to(policeAttackGun, 0.5, {...showElemWithAria});

  timeline.markers.createTimelineMarker();

  // console.log(timeline.duration())
  timeline.to(
    policeAttackParagraph2,
    1.5,
    { ...showElemWithAria },
  );

  timeline.to([
    policeAttackSubhead,
    policeAttackCredit,
    policeAttackParagraph
  ], 0, {...unmakeElementTabbable}, "-=1.49")
  timeline.to([
    policeAttackParagraph2
  ], 0, {...makeElementTabbable}, "-=1.49")

  timeline.markers.createTimelineMarker();

  timeline.to(policeAttack, 1.5, {
    ...hideElementWithAria,
    ...setDarkOnStart
  });

  timeline.to(jljDead, 1.5, { ...showElemWithAria });
  timeline.to(jljDeadText, 1.5, { ...showElemWithAria }, "-=1.5");
  timeline.to(jljDeadImg, 0.001, {...addTab});

  placeholder(timeline, 0.5);

  timeline.to(jljDeadWhite, 1.5, {
    ...hideElementWithAria,
    ...setLightOnStart
  });
  timeline.to(jljDeadText, 1.5, { className: '+=white' }, '-=1.5');

  timeline.markers.createTimelineMarker();

  timeline.to(jljDead, 1.5, { ...hideElementWithAria });
  timeline.to(jljDeadImg, 0.001, {...removeTab});
  timeline.to(reactionsCoverageImg, 0.001, {...addTab});
  timeline.to(reactions, 0.5, { ...showElemWithAria });

  placeholder(timeline, 0.5);

  timeline.fromTo(
    reactionsCoverageTitle,
    1.5,
    { y: '+=20' },
    { y: '-=20', ...showElemWithAria, ...easeOut }
  );
  timeline.fromTo(
    reactionsCoverageParagraph,
    1.5,
    { y: '+=20' },
    { y: '-=20', ...showElemWithAria, ...easeOut },
    "-=1.25"
  );

  timeline.markers.createTimelineMarker();

  timeline.to([reactionsCoverage], 0.5, { ...hideElementWithAria });
  timeline.to(reactionsCoverageImg, 0.001, {...removeTab});
  timeline.to([reactionsMarch, reactionsMarchImg], 0.5, { ...showElemWithAria }, '-=0.5');

  timeline.to(reactionsCoverageTitle, 0.5, { ...hideElementWithAria }, '-=0.5');
  timeline.to(reactionsCoverageParagraph, 0.5, { ...hideElementWithAria }, '-=0.5');

  timeline.to(reactionsBevelImg, 0.5, { ...showElemWithAria, ...addTab });
  timeline.fromTo(
    reactionsBevelText,
    1.5,
    { y: '+=20' },
    { y: '-=20', ...showElemWithAria, ...easeOut }, "-=0.25"
  );
  timeline.to(reactionsBevelArrow, 0.5, { ...showElemWithAria }, '-=1.25');

  timeline.fromTo(
    reactionsMarchParagraph,
    1.5,
    { y: '+=20' },
    { y: '-=20', ...showElemWithAria, ...easeOut },
    "-=0.75"
  );
  timeline.fromTo(
    reactionsMarchTitle,
    1.5,
    { y: '+=20' },
    { y: '-=20', ...showElemWithAria, ...easeOut },
    "-=1.25"
  );

  timeline.markers.createTimelineMarker();
};

const query = graphql`
  query {
    BrickWall: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch-5-image-brick-background-b.png"
      }
    ) {
      ...largeImage
    }

    RestRoomSign: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch-5-image-restroom-sign@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1730) {
          ...selmaFluidImage
        }
      }
    }

    CtvFg: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch5-image-clark_and_ctvivian-a@2x.jpg"
      }
    ) {
      ...largeImage
    }

    CtvBg: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch5-image-clark_and_ctvivian-b@2x.jpg"
      }
    ) {
      ...largeImage
    }

    BillOfRights: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch-5-image-billofrights@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1138) {
          ...selmaFluidImage
        }
      }
    }

    Persist: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch-5-image-nevertheless-background@2x.png"
      }
    ) {
      ...largeImage
    }

    JamesOrangeBg: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch-5-image-marion-background@2x.png"
      }
    ) {
      ...largeImage
    }

    JamesOrangePortrait: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch-5-image-james-orange@2x.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 724) {
          ...selmaFluidImage
        }
      }
    }

    PerryCountyJail: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch5-image-perry_county_jail.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 540) {
          ...selmaFluidImage
        }
      }
    }

    Singers: file(
      relativePath: { eq: "chapter-5/scene-1/images/ch5-image-singers.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 565) {
          ...selmaFluidImage
        }
      }
    }

    OmarDorsey: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch-5-image-omar-dorsey@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1002) {
          ...selmaFluidImage
        }
      }
    }

    ArmedTroopers: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch-5-image-armed-troopers@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...selmaFluidImage
        }
      }
    }

    OfficerGun: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch-5-image-officer-gun@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 720) {
          ...selmaFluidImage
        }
      }
    }

    JimmyLeeJackson: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch5-image-jimmie_lee_jackson.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 600) {
          ...selmaFluidImage
        }
      }
    }

    JlJdead: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch-5-image-jimmie-lee-jackson-dead@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...selmaFluidImage
        }
      }
    }

    JlJFuneralOutside: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch-5-image-jackson-funeral-outside@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1438) {
          ...selmaFluidImage
        }
      }
    }

    JlJFuneralInside: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch-5-image-jackson-funeral-inside@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1438) {
          ...selmaFluidImage
        }
      }
    }

    ArrowDown: file(
      relativePath: {
        eq: "chapter-3/scene-13/images/ch3-doodle-arrow-confrontation_jim_clark.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 208) {
          ...selmaFluidImage
        }
      }
    }

    Bevel: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch5-polaroid-james_bevel.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...selmaFluidImage
        }
      }
    }

    BevelArrow: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch-5-doodle-arrow-james-bevel@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 180) {
          ...selmaFluidImage
        }
      }
    }
    persistBg: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch5-image-persist-black.jpg"
      }
    ) {
      ...largeImage
    }
    persistFg: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch5-image-persist-white.jpg"
      }
    ) {
      ...largeImage
    }

    DeputiesBg: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch5-image-perry_county_deputies-bw.jpg"
      }
    ) {
      ...largeImage
    }

    DeputiesFg: file(
      relativePath: {
        eq: "chapter-5/scene-1/images/ch5-image-perry_county_deputies-red.jpg"
      }
    ) {
      ...largeImage
    }
  }
`;

const SceneComponent = scene(Timeline(C5S1, {animation}));
export default props => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
