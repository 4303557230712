import React, {Component} from 'react';
import {graphql} from 'gatsby';

import ChapterPage from 'components/chapter';
import ChapterIntro from 'templates/ChapterIntro';
import ChapterConclusion from 'components/ChapterConclusion';

import Chapter5Scene1 from 'components/scenes/chapter-5/scene-1/Chapter5Scene1';
import Chapter5Scene2 from 'components/scenes/chapter-5/scene-2/Chapter5Scene2';
import Chapter5Scene3 from 'components/scenes/chapter-5/scene-3/Chapter5Scene3';
import Chapter5Scene4 from 'components/scenes/chapter-5/scene-4/Chapter5Scene4';
import Chapter5Scene5 from 'components/scenes/chapter-5/scene-5/Chapter5Scene5';
import Chapter5Scene6 from 'components/scenes/chapter-5/scene-6/Chapter5Scene6';
import Chapter5Scene7 from 'components/scenes/chapter-5/scene-7/Chapter5Scene7';
import Chapter5Scene8 from 'components/scenes/chapter-5/scene-8/Chapter5Scene8';
import Chapter5Scene9 from 'components/scenes/chapter-5/scene-9/Chapter5Scene9';
import Chapter5Scene10 from 'components/scenes/chapter-5/scene-10/Chapter5Scene10';
import Chapter5Scene11 from 'components/scenes/chapter-5/scene-11/Chapter5Scene11';
import {Chapter5Qa} from 'components/scenes/chapter-5/scene-11/Chapter5Qa';

class Chapter5 extends React.Component {
  render() {
    const {data} = this.props;
    const {heroImage, Chapter5End} = data;

    const title = 'Selma’s Pivotal Moments';

    return (
      <div className="chapter-5">
        <ChapterPage
          chapterNumber={2}
          title={title}
          scenes={[
            <ChapterIntro
              title="Chapter 5"
              subtitle="Selma’s Pivotal Moments"
              hero={heroImage}
              alt="Civil rights activists march across the Edmund Pettus Bridge, starting the second march to Montgomery. In the first march, the marchers had been attacked and beaten by Alabama state troopers and local law enforment. Only the third march actually made it all the way to Montgomery. (Photo by © Flip Schulke/CORBIS/Corbis via Getty Images)"
            />,
            <Chapter5Scene1 />,
            <Chapter5Scene2 />,
            <Chapter5Scene3 />,
            <Chapter5Scene4 />,
            <Chapter5Scene5 />,
            <Chapter5Scene6 />,
            <Chapter5Scene7 />,
            <Chapter5Scene8 />,
            <Chapter5Scene9 />,
            <Chapter5Scene10 />,
            <Chapter5Scene11 />,
            <ChapterConclusion
              image={Chapter5End}
              questions={Chapter5Qa}
              chapterNumber={5}
              nextChapterTitle={'Presidential Support'}
              theme="dark"
            />,
          ]} />
      </div>
    );
  }
}

export const query = graphql`
  query {
    heroImage: file(relativePath: { eq: "chapter-5/chapter-5-hero@2x.jpg" }) {
      ...largeImage
    }
    Chapter5End: file(relativePath: { eq: "chapter-5/scene-11/images/ch5-image-endframe_lincoln_statue.jpg" }) {
      ...largeImage
    }
  }
`;

export default Chapter5;
