import React, { Component } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import './chapter-5-scene-5.scss';

import Timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import FullScreenImage from 'components/FullScreenImage';
import { Image } from 'components/Image';
import SplitView from 'templates/SplitView';
import {tabbable} from 'utils/accessibility'

import {getPercentageBetweenBounds} from 'utils'
import {
  placeholder, hideAllRefs, showElemWithAria, hideElementWithAria
} from 'utils/animation';
import scene from 'components/scene';
import {TimelineMarkers} from 'utils/scene'

import VideoAnimation from 'components/VideoAnimation';

import video1 from './video/ch5-scrub-bloody_sunday_01.mp4'
import video2 from './video/ch5-scrub-bloody_sunday-02.mp4'
import { addTab, removeTab } from '../../../../utils/animation';

// value retrieved from logging out the timeline's duration value to the console
const animationDuration = 17.8

// numerators retrieved from logging out the timeline's duration at the point
// at which we want these values to start/end
const video1Start = 9 / animationDuration;
const video2Start = 11 / animationDuration;
const video2End = 15 / animationDuration;

class Chapter5Scene5 extends Component {
  render() {
    const { data, position} = this.props;
    const {
      MapOut,
      MapIn,
      MapLine1,
      Leaders,
      BrownsCircle,
      BloodySunday,
      PoliceBrigade,
      Marchers,
      DoodleX,
      ArrowHosea,
      ArrowLewis,
      MapCircleAndX,
    } = data;

    const video1Progress = getPercentageBetweenBounds(position, video1Start, video2Start)
    const video2Progress = getPercentageBetweenBounds(position, video2Start, video2End)

    return (
      <div className="chapter-5-scene-5">
        <FullScreenContainer>
          <FullScreenContainer className="map">
            <div className="map__zoom-out" ref="mapZoomOutBg">
              <FullScreenImage
                image={MapOut}
                aspectRatio={8 / 5}
                alt="Map Out">
                <Image image={MapCircleAndX} className="circle-and-x"/>
              </FullScreenImage>
            </div>

            <div className="map__zoom-in" ref="mapZoomIn">
              <div ref="mapTranslate">
                <FullScreenImage
                  image={MapIn}
                  aspectRatio={8 / 5}
                  alt="Map In">
                  <div>
                    <div className="path-1" ref="mapZoomInPath1">
                      <Image image={MapLine1} alt="Map line"/>
                    </div>

                    <div className="browns-chapel">
                      <div className="browns-chapel__text">
                        <h4 ref="leadersChapel">Brown Chapel</h4>
                      </div>
                      <div className="browns-chapel__circle" ref="leadersCircle">
                        <Image image={BrownsCircle} alt="Doodle Circle"/>
                      </div>
                    </div>
                  </div>
                </FullScreenImage>
              </div>
            </div>
          </FullScreenContainer>

          <div className="map__title" ref="mapTitle">
            <FullScreenContainer>
              <div {...tabbable} className="title">
                <h1 className="title-big" ref="date">
                  March 7, 1965
                </h1>
                <h1 className="highlight" ref="title">
                  Bloody Sunday
                </h1>
              </div>
            </FullScreenContainer>
          </div>

          <div className="map__leaders" ref="mapLeaders">
            <SplitView
              fullWidthPanel={
                <div className="leaders-left">
                  <div className="portrait" ref="leadersPortrait" role='img' aria-label="7/26/1965-Atlanta, GA: John Lewis (L), chairman of the Student Nonviolent Coordinating Committee (SNCC), and Hosea Williams, of the Southern Christian Leadership Conference (SCLC), announced campaigns of racial demonstrations to 'explode the false image of racial harmony' in Georgia. The two said Sumter and Baker counties were prime targets and announced plans for a mass 'street vigil' in Americus 7/27.">
                    <Image image={Leaders} alt="Leaders"/>
                  </div>
                  <p {...tabbable} className="subhead" ref="leadersText" aria-label="In the absence of King (preaching at his church in Atlanta),
                    Hosea Williams of SCLC and John Lewis of SNCC lead the 600
                    protesters who gather at the Edmund Pettus Bridge.">
                    In the absence of King (preaching at his church in Atlanta),
                    Hosea Williams of SCLC and John Lewis of SNCC lead the 600
                    protesters who gather at the Edmund Pettus Bridge.
                  </p>
                  <div className="arrows">
                    <div className="arrow hosea" ref="leadersHosea">
                      <h4>Hosea Williams</h4>
                      <div className="img">
                        <Image image={ArrowHosea} alt="Arrow"/>
                      </div>
                    </div>

                    <div className="arrow lewis" ref="leadersLewis">
                      <div className="img">
                        <Image image={ArrowLewis} alt="Arrow"/>
                      </div>
                      <h4>John Lewis</h4>
                    </div>
                  </div>
                </div>
              }
              rightPanel={
                <div className="leaders-right">
                </div>
              }
            />
          </div>

          <div className="map__march" ref="selmaMarch">
            <VideoAnimation video={video1} sections={[
              {
                breakpoints: [0, 1],
                keyframes: [0, 1]
              }
            ]} progress={video1Progress} />
          </div>

          <div className="map__bridge" ref="selmaBridge">
            <VideoAnimation video={video2} sections={[
              {
                breakpoints: [0, 1],
                keyframes: [0, 1]
              }
            ]} progress={video2Progress} />
          </div>

          <div className="map__arrest" ref="arrest">
            <FullScreenContainer>
              <div className="arrest__bg" ref="arrestBg">
                <Image image={BloodySunday} alt="Bloody Sunday"/>
              </div>
              <div className="arrest__brigade" ref="arrestBrigade">
                <Image image={PoliceBrigade} alt="Police Brigade" caption="© 1965 Spider Martin"/>
              </div>
              <div className="arrest__marchers" ref="arrestMarchers" role='img' aria-label="Two minute warning On March 7, 1965, 600 civil rights protesters attempted a march from Selma to Montgomery, Alabama, the state capital, to draw attention to the voting rights issue. Led by Hosea Williams (at right front in dark raincoat) and John Lewis (at right in light-colored coat), the marchers crossed the Edmund Pettus Bridge over the Alabama River on their way to Montgomery. There they encountered Alabama state troopers and local police officers who gave them a two-minute warning to stop and turn back. When the protesters refused, the officers tear-gassed and beat them. Over 50 people were hospitalized. The events became known as “Bloody Sunday” and were televised worldwide. A few weeks later a march from Selma to Montgomery was completed under federal protection. Later than year, on August 6, 1965—partly due to the efforts of civil rights activists in Selma and around the nation—President Lyndon Johnson signed into law the Voting Rights Act. This act attempted to remove barriers faced by African Americans in exercising their constitutional right to vote. Photo taken by Spider Martin. National Archives photo.">
                <Image image={Marchers} alt=""/>
              </div>
              <div className="arrest__doodle" ref="arrestDoodle">
                <Image image={DoodleX} alt="Doodle X"/>
              </div>
              <div {...tabbable} className="arrest__text" ref="arrestText">
                <p className="subhead">They expect to be arrested—</p>
                <p className="alt">
                  as many of them have been before—and they expect violence from
                  150 Alabama state troopers, sheriff’s deputies, and Jim
                  Clark’s posse, who wait for them across the bridge.
                </p>
              </div>
            </FullScreenContainer>
          </div>
        </FullScreenContainer>
      </div>
    );
  }
}

const animation = ({context, timeline, easeOut, easeIn}) => {

  const {
    mapZoomOutBg,
    mapTitle,
    mapLeaders,
    date,
    title,
    mapZoomIn,
    mapTranslate,
    mapZoomInPath1,
    leadersPortrait,
    leadersText,
    leadersHosea,
    leadersLewis,
    leadersChapel,
    leadersCircle,
    selmaMarch,
    selmaBridge,
    arrest,
    arrestBg,
    arrestBrigade,
    arrestMarchers,
    arrestDoodle,
    arrestText
  } = context.refs;

  hideAllRefs(context.refs, [
    'mapTitle',
    'mapZoomOutBg',
    'mapTranslate',
    'arrest'
  ]);

  timeline.fromTo(date, 1.5, { y: '+=20' }, { y: '-=20', ...showElemWithAria, ...easeOut });
  timeline.fromTo(title, 1.5, { y: '+=20' }, { y: '-=20', ...showElemWithAria, ...easeOut }, "-=1.25");

  timeline.markers.createTimelineMarker();

  timeline.to(mapZoomOutBg, 2, { z: 500, x: '50%', y: '25%', rotation: '+=10deg', ease: Expo.easeInOut });

  timeline.to(mapTitle, 1.5, { ...hideElementWithAria}, '-=2')
  timeline.to(date, 1.5, { y: '-=50', ...hideElementWithAria, ...easeOut }, '-=2');
  timeline.to(title, 1.5, { y: '-=50', ...hideElementWithAria, ...easeOut }, "-=2");

  TweenMax.set(mapZoomIn, {z: -250, y: "-25%", x: "-40%", rotation: "-=5deg"})
  timeline.to(mapZoomIn, 2, { z: 0, y: "0%", x: "0%", rotation: "0deg", ease: Expo.easeInOut }, '-=2');
  timeline.to(mapZoomIn, 0.5, {...showElemWithAria}, "-=1")
  timeline.to(mapZoomOutBg, 0.5, { ...hideElementWithAria }, "-=1");

  timeline.to(mapZoomInPath1, 0.5, { ...showElemWithAria }, "-=0.5");

  const mapAspectRatio = 0.625
  const windowAspectRatio = window.innerHeight / window.innerWidth

  let xAdjust = 1
  let yAdjust = 1
  if (windowAspectRatio > mapAspectRatio) {
    xAdjust = (window.innerWidth / (window.innerHeight / mapAspectRatio) )
  } else {
    yAdjust = (window.innerHeight / (window.innerWidth * mapAspectRatio) )
  }

  timeline.to(mapZoomIn, 1.5, {
    rotation: '+=26deg',
    z: 250,
    ease: Expo.easeInOut
  });

  const chapelCenterX = 7.75
  const chapelCenterY = 6

  timeline.to(mapTranslate, 1.5, {
    x: `${chapelCenterX / xAdjust}%`,
    y: `${chapelCenterY / yAdjust}%`,
    ease: Expo.easeInOut
  }, "-=1.5");


  // placeholder(timeline, 0.3);
  // timeline.markers.createTimelineMarker()

  timeline.to(mapZoomInPath1, 0.5, { ...hideElementWithAria });
  timeline.to(mapLeaders, 0.5, {...showElemWithAria}, "-=0.5")
  timeline.fromTo(
    leadersPortrait,
    1.5,
    { y: '-=50' },
    { y: '+=50', ...showElemWithAria, ...addTab, ...easeOut }
  );
  timeline.fromTo(leadersText, 1.5, { y: '+=100' }, { y: '-=100', ...showElemWithAria, ...easeOut }, "-=1.25");
  timeline.fromTo(
    leadersHosea,
    1.5,
    { x: '+=50' },
    { x: '-=50', ...showElemWithAria, ...easeOut },
    '-=1.25'
  );
  timeline.fromTo(
    leadersLewis,
    1.25,
    { x: '-=50' },
    { x: '+=50', ...showElemWithAria, ...easeOut },
    '-=1.25'
  );
  timeline.fromTo(
    leadersChapel,
    1.5,
    { y: '-=50' },
    { y: '+=50', ...showElemWithAria, ...easeOut },
    '-=1.25'
  );

  timeline.to(leadersCircle, 0.5, { ...showElemWithAria }, "-=1.25");

  timeline.markers.createTimelineMarker();


  timeline.to(
    [leadersPortrait, leadersHosea, leadersLewis, leadersText],
    1,
    { ...hideElementWithAria, ...removeTab, ...easeOut }
  );

  timeline.to([leadersCircle, leadersChapel], 1, {...hideElementWithAria, ...easeOut}, "-=1")

  timeline.to(mapZoomInPath1, 1, {...showElemWithAria, ...easeOut}, "-=1");

  // timeline.to(leadersChapel, 0.3, { y: '-=350' });
  // timeline.to(leadersCircle, 0.3, { y: '-=350' }, '-=0.3');

  const pathStopY = -7.25
  const pathEndX = 3.25
  const pathEndY = -32

  // console.log(timeline.duration())
  timeline.to(mapZoomIn, 2, { y: `${pathStopY / yAdjust}%`, ease: Quad.easeInOut }, "-=0.5");
  timeline.to(mapZoomIn, 2, { x: `${pathEndX / xAdjust}%`, ease: Quad.easeInOut }, "-=0.5");
  timeline.to(mapZoomIn, 3, { y: `${pathEndY / yAdjust}%`, ease: Quad.easeInOut }, "-=0.5");
  //
  timeline.to(selmaMarch, 1, { autoAlpha: 0.8, attr: {'aria-hidden': false} }, '-=5');
  timeline.to(selmaMarch, 0.01, { ...hideElementWithAria }, '-=3');
  timeline.to(selmaBridge, 0.01, { autoAlpha: 0.8, attr: {'aria-hidden': false} }, '-=3');
  timeline.to(selmaBridge, 1, { ...hideElementWithAria }, '-=0.5');
  // console.log(timeline.duration())


  timeline.to(arrestDoodle, 0.5, { ...showElemWithAria });
  placeholder(timeline, 0.25)

  timeline.to(arrest, 0.5, { className: '+=dark' });
  timeline.to(arrestBg, 0.5, { ...showElemWithAria });

  timeline.fromTo(
    arrestBrigade,
    1.5,
    { x: '-=50' },
    { x: '+=50', ...showElemWithAria, ...easeOut }, "-=0.5"
  );
  timeline.fromTo(
    arrestMarchers,
    1.5,
    { x: '+=50' },
    { x: '-=50', ...showElemWithAria, ...addTab, ...easeOut},
    '-=1.5'
  );

  timeline.fromTo(arrestText, 1.5, { y: '+=50' }, { y: '-=50', ...showElemWithAria, ...easeOut }, "-=1.25");
  // console.log(timeline.duration())

};

const query = graphql`
  query {
    MapOut: file(
      relativePath: { eq: "chapter-5/scene-5/images/ch-5-image-mapbg@2x.png" }
    ) {
      ...largeImage
    }

    MapIn: file(
      relativePath: {
        eq: "chapter-5/scene-5/images/ch-5-image-selma-mapbg@2x.png"
      }
    ) {
      ...largeImage
    }

    MapLine1: file(
      relativePath: {
        eq: "chapter-5/scene-5/images/ch-5-doodle-mapline@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 416) {
          ...selmaFluidImage
        }
      }
    }

    Leaders: file(
      relativePath: {
        eq: "chapter-5/scene-5/images/ch-5-image-johnlewis-hoseawilliams@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 874) {
          ...selmaFluidImage
        }
      }
    }

    BrownsCircle: file(
      relativePath: {
        eq: "chapter-5/scene-5/images/ch-5-doodle-brownscircle@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 874) {
          ...selmaFluidImage
        }
      }
    }

    BloodySunday: file(
      relativePath: {
        eq: "chapter-5/scene-5/images/ch-5-image-bloodysunday-bg@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 2880) {
          ...selmaFluidImage
        }
      }
    }

    PoliceBrigade: file(
      relativePath: {
        eq: "chapter-5/scene-5/images/ch-5-image-police-brigade@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1120) {
          ...selmaFluidImage
        }
      }
    }

    Marchers: file(
      relativePath: {
        eq: "chapter-5/scene-5/images/ch-5-image-marchers@2x.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 790) {
          ...selmaFluidImage
        }
      }
    }

    DoodleX: file(
      relativePath: { eq: "chapter-5/scene-5/images/ch-5-doodle-x@2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 364) {
          ...selmaFluidImage
        }
      }
    }

    ArrowHosea: file(
      relativePath: { eq: "chapter-5/scene-5/images/ch-5-doodle-arrow-hosea-williams@2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 364) {
          ...selmaFluidImage
        }
      }
    }

    ArrowLewis: file(
      relativePath: { eq: "chapter-5/scene-5/images/ch-5-doodle-arrow-john-lewis@2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 364) {
          ...selmaFluidImage
        }
      }
    }

    MapCircleAndX: file(
      relativePath: { eq: "chapter-5/scene-5/images/ch-5-doodle-mapcircle-and-x@2x.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1018) {
          ...selmaFluidImage
        }
      }
    }

  }
`;

const SceneComponent = scene(
  Timeline(Chapter5Scene5, { animation })
);
export default props => {
  const data = useStaticQuery(query);
  return <SceneComponent data={data} {...props} />;
};
