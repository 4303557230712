import React from 'react';
import PropTypes from 'prop-types';
import timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import {AppSettings} from 'components/Layout';
import Image from 'components/Image';

import {TweenMax} from 'gsap';

import 'styles/components/image-swap.scss';

const ImageSwap = class extends React.Component {
  static contextType = AppSettings

  render() {
    const {className, aspectRatio} = this.props;
    return (
      <div className={`image-swap ${className}`}>
        {aspectRatio &&
          <div className="image-swap__aspect-ratio" style={{paddingTop: `${aspectRatio}%`}}>
            {this.renderContent()}
          </div>
        }
        {!aspectRatio &&
          <FullScreenContainer>
            {this.renderContent()}
          </FullScreenContainer>
        }
      </div>
    );
  }

  renderContent() {
    const {image, image2, alt, image1ClassName, transparent, showAria, alt2, showAria2} = this.props;
    const image1Class = ['image', image1ClassName].filter((s) => Boolean(s)).join(' ');
    return (
      <div className="bg-container" aria-hidden={!showAria}>
        {!transparent &&
          <div className="white-bg"></div>
        }
        {!image2 &&
          <div className="red-bg" ref={(ref) => (this.mask = ref)}></div>
        }
        <Image className={image1Class} image={image} showAria={showAria} alt={alt} sizes="100vw" />
        {image2 &&
          <div className="second-image" ref={(ref) => (this.mask = ref)}>
            <div className="second-image__mask" ref={(ref) => (this.maskedImage = ref)}>
              <Image className="image" image={image2} showAria={showAria2} alt={alt2} sizes="100vw" />
            </div>
          </div>
        }
      </div>
    );
  }
};

const animation = ({context, timeline, easeOut}) => {
  const {mask, maskedImage} = context;
  const {screenHeight} = context.context;
  const {image2} = context.props;

  if (image2) {
    TweenMax.set(mask, {y: screenHeight});
    TweenMax.set(maskedImage, {y: -screenHeight});

    timeline.to(mask, 2, {y: 0, ...easeOut});
    timeline.to(maskedImage, 2, {y: 0, ...easeOut}, '-=2');
  } else {
    TweenMax.set(mask, {y: screenHeight + 200});

    timeline.to(mask, 2, {y: -150, ...easeOut});
  }
};

ImageSwap.propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  image2: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  alt: PropTypes.string,
  transparent: PropTypes.bool,
  aspectRatio: PropTypes.number,
  className: PropTypes.string,
};

export default timeline(ImageSwap, {animation});
