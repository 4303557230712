import React, { Component } from 'react';
import { useStaticQuery, graphql } from "gatsby"

import './chapter-5-scene-3.scss';
import Timeline from 'components/timelineComponent';
import SplitView from 'templates/SplitView';
import {viewTypes} from 'templates/SplitView';
import {Image} from 'components/Image';
import FullScreenImage from 'components/FullScreenImage';
import ImageSwapAngle from 'components/ImageSwapAngle';
import {tabbable} from 'utils/accessibility'

import {
  placeholder, hideAllRefs, showElemWithAria, hideElementWithAria, makeElementTabbable, unmakeElementTabbable
} from 'utils/animation';
import { getPercentageBetweenBounds } from 'utils'
import scene from 'components/scene';
import {TimelineMarkers} from 'utils/scene'
import { addTab, removeTab } from '../../../../utils/animation';

const imageSwapStart = 0.75;
const imageSwapEnd = 0.99;


class Chapter5Scene3 extends Component {
  render() {
    const { position, data } = this.props
    const {
      MapBackground, JimmieLee, MapCircle, MapLine, MlkSpeaking, Troopers, Protesters
    } = data

    const imageSwapProgress = getPercentageBetweenBounds(
        position, imageSwapStart, imageSwapEnd
    );

    return (
      <div className="chapter-5-scene-3">
        <div className="march" ref="march">
          <SplitView
            fullWidthPanel={(
              <div className="march__map">
                <FullScreenImage
                  image={MapBackground}
                  aspectRatio={8/5}
                  className="map-bg"
                  alt="Map, Selma to Montgomery"
                >
                  <div className="map-circle">
                    <Image image={MapCircle} alt="Doodle Map Circle"/>
                  </div>
                  <div className="map-line">
                    <Image image={MapLine} alt="Doodle Map Line"/>
                  </div>
                </FullScreenImage>
              </div>
            )}
            leftPanel={(
              <div {...tabbable} className="march__left">
                <h3 ref="marchTitle">
                  The Selma to Montgomery march is planned for March 7, 1965, to honor Jimmie Lee Jackson.
                </h3>
                <p className="alt" ref="marchParagraph">
                Some in SNCC oppose the march because of their growing disagreements with King on tactics and strategy.
                </p>
              </div>
            )}
            rightPanel={(
              <div className="march__right">
                <div className="jlj" aria-label="Jimmie Lee Jackson" role='img' ref="jljImg">
                  <Image image={JimmieLee} alt="Jimmie Lee Jackson"/>
                </div>
              </div>
            )}
          />
        </div>

        <div className="mlk" ref="mlk">
          <SplitView
            viewType={viewTypes.TWO_THIRDS}
            leftPanel={(
              <div className="mlk__left">
                <div className="mlk-img" ref="mlkImg" role='img' aria-label="Nashville: Rev. Martin Luther King Jr. speaks to a packed crowd at Fisk University here and urges them to continue their demonstrations. Nashville has had seven days of racial demonstrations that began last April 27. King arrived at the airport and told newsmen that he had not been asked to participate in the current demonstrations.">
                  <Image image={MlkSpeaking} alt=""/>
                </div>
              </div>
            )}
            rightPanel={(
              <div {...tabbable} className="mlk__right">
                <p className="alt" ref="mlkParagraph1">
                  Some in SCLC fear a repeat of the Marion violence and advise King to remain in Atlanta.
                </p>
                <p className="alt" ref="mlkParagraph2">
                  Organizers also anticipate state troopers will block the march, so King's role from Atlanta will be to "mobilize national support for a larger thrust forward."
                </p>
              </div>
            )}
          />
        </div>

        <div className="marchers" ref="marchers">
          <SplitView
            viewType={viewTypes.ONE_THIRD}
            leftPanel={(
              <div className="marchers__left">
                <p className="alt" ref="marchersParagraph">
                  Both groups—SNCC and SCLC—know that the march is illegal without a federal court order and that Governor Wallace has ordered Alabama state troopers to “use whatever measures are necessary” to prevent the march.
                </p>
                <h4 ref="marchersTitle">
                  But enthusiasm for the march is very strong. The community wants to honor Jimmie Lee Jackson, so the groups agree to proceed that Sunday morning.
                </h4>
              </div>
            )}
            rightPanel={(
              <div className="marchers__right" ref="marchersSwap">
                <ImageSwapAngle
                  image={Troopers}
                  image2={Protesters}
                  alt="Armed Alabama State Troopers walk along a sidewalk as they wait for demonstrators on the first Selma to Montgomery March, Selma, Alabama, March 7, 1965. The violent response of the police and state troopers led to the event becoming known as 'Bloody Sunday.' (Photo by Charles Moore/Getty Images)"
                  alt2="Religious and Civil Rights leaders and others hold hands in song and prayer prior to the start of the first Selma to Montgomery March, Selma, Alabama, March 7, 1965. Among those pictured are, right from center, Robert Mants (1943 - 2011) (center, in dark coat), John Lewis, unidentified, Andrew Young, Hosea Williams (1926 - 2000), and Amelia Boynton (1911 - 2015). The violence that ensued when police and state troopers attacked the marchers led to the event being called 'Bloody Sunday.' (Photo by Charles Moore/Getty Images)"
                  showAria1
                  showAria2
                  aspectRatio={72}
                  position={imageSwapProgress}
                  offsetLeft={30}
                  className="img-swap"
                />
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}

const animation = ({context, timeline, easeOut, easeIn}) => {


  const {march,
    marchTitle, marchParagraph,
    mlk, mlkParagraph1, mlkParagraph2,
    marchers, marchersParagraph, marchersTitle, marchersSwap, jljImg, mlkImg
  } = context.refs;

  hideAllRefs(context.refs, ['march', 'marchersSwap', 'jljImg', 'mlkImg']);
  timeline.to(jljImg, 0.01, {...addTab});
  timeline.fromTo(marchTitle, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut});
  timeline.fromTo(marchParagraph, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut},
      "-=1.25"
  );
  
  timeline.markers.createTimelineMarker()
  timeline.to(jljImg, 0.01, {...removeTab});
  timeline.to(mlkImg, 0.01, {...addTab});
  timeline.to(march, 1, {...hideElementWithAria});
  timeline.to(mlk, 1, {...showElemWithAria}, "-=1");

  timeline.fromTo(mlkParagraph1, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=0.5"
  );
  timeline.fromTo(mlkParagraph2, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=1.25"
  );

  timeline.markers.createTimelineMarker()

  timeline.to(mlk, 1, {...hideElementWithAria});
  timeline.to(mlkImg, 0.01, {...removeTab});
  timeline.to(marchers, 1, {...showElemWithAria}, '-=1');
  timeline.to(marchersParagraph, 0, {...makeElementTabbable})
  timeline.fromTo(marchersParagraph, 1.5,
      {y: '+=50'}, {y: 0, ...showElemWithAria, ...easeOut}
  );

  timeline.markers.createTimelineMarker()

  timeline.fromTo(marchersTitle, 1.5,
      {y: '+=50'}, {y: 0, ...showElemWithAria, ...easeOut}
  );
  timeline.to(marchersParagraph, 0, {...unmakeElementTabbable}, "-=1.49")
  timeline.to(marchersTitle, 0, {...makeElementTabbable}, "-=1.49")

  placeholder(timeline, 1)

  timeline.markers.createTimelineMarker()

};

const query = graphql`
  query {
    MapBackground: file(relativePath: { eq: "chapter-5/scene-3/images/ch-5-image-map-background@2x.png" }) {
      ...largeImage
    }

    JimmieLee: file(relativePath: { eq: "chapter-5/scene-3/images/ch5-poloroid-jimmie_lee_jackson.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 466) {
          ...selmaFluidImage
        }
      }
    }

    MapCircle: file(relativePath: { eq: "chapter-5/scene-3/images/ch-5-doodle-map-circle@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 498) {
          ...selmaFluidImage
        }
      }
    }

    MapLine: file(relativePath: { eq: "chapter-5/scene-3/images/ch-5-doodle-map-line@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1126) {
          ...selmaFluidImage
        }
      }
    }

    MlkSpeaking: file(relativePath: { eq: "chapter-5/scene-3/images/ch-5-image-mlk-us-map@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1848) {
          ...selmaFluidImage
        }
      }
    }

    Troopers: file(relativePath: { eq: "chapter-5/scene-3/images/ch-5-image-troopers-red__black@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...selmaFluidImage
        }
      }
    }

    Protesters: file(relativePath: { eq: "chapter-5/scene-3/images/ch-5-image-protesters-arminarm-sepia__black@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1904) {
          ...selmaFluidImage
        }
      }
    }
  }
`
const SceneComponent = scene(Timeline(Chapter5Scene3, {animation}))
export default (props) => {
  const data = useStaticQuery(query)
  return <SceneComponent data={data} {...props}/>
}
