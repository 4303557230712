import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import MovieScene from 'components/MovieScene';
import scene from 'components/scene';
import Clip from './ch5-SCENE-09-Turnaround_Tuesday.mp4';

const Chapter5Scene10 = ({data}) => (
  <div className="chapter-4-scene-4">
    <MovieScene
      sceneNumber={9}
      sceneSubtitle={
        <span><span className="italic">SELMA</span> SCENE 9</span>
      }
      sceneDescription="Turnaround Tuesday: Tactics vs. the Constitution"
      firstImage={data.TurnaroundTuesdayReplay}
      lastImage={data.TurnaroundTuesdayReplay}
      videoSrc={Clip}
    />

  </div>
);

const query = graphql`
  query {
    TurnaroundTuesdayReplay: file(relativePath: { eq: "chapter-5/scene-10/images/ch-5-image-turnaround-tuesday-replay@2x.jpg" }) {
      ...largeImage
    }
  }
`;

const ChapterComponent = scene(Chapter5Scene10);
export default (props) => {
  const data = useStaticQuery(query);
  return <ChapterComponent data={data} {...props}/>;
};
