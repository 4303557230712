import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import MovieScene from 'components/MovieScene';
import scene from 'components/scene';
import Clip from './ch5-SCENE-06-MLK_Asks_LBJ_For_Support.mp4';

const Chapter5Scene2 = ({data}) => (
  <div className="chapter-5-scene-2">
    <MovieScene
      sceneNumber={5}
      sceneSubtitle={
        <span><span className="italic">SELMA</span> SCENE 6</span>
      }
      sceneDescription="MLK asks LBJ to Support the March"
      firstImage={data.DebatingFirst}
      lastImage={data.DebatingLast}
      videoSrc={Clip}
    />
  </div>
);

const query = graphql`
  query {
    DebatingFirst: file(relativePath: { eq: "chapter-5/scene-2/images/ch-5-image-debating-videostill@2x.png" }) {
      ...largeImage
    }
    DebatingLast: file(relativePath: { eq: "chapter-5/scene-2/images/ch-5-image-debating-videoreplay@2x.png" }) {
      ...largeImage
    }
  }
`;
const ChapterComponent = scene(Chapter5Scene2);
export default (props) => {
  const data = useStaticQuery(query);
  return <ChapterComponent data={data} {...props}/>;
};
