import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';

import MovieScene from 'components/MovieScene';
import scene from 'components/scene';
import Clip from './ch5-SCENE-01_ARCHIVE-Bloody_Sunday.mp4';
const subtitle = <span><span className="italic">ARCHIVAL</span> CLIP</span>;

const Chapter5Scene6 = ({data}) => (
  <div className="chapter-4-scene-4">
    <MovieScene
      sceneNumber={6}
      sceneSubtitle={
        subtitle
      }
      noSound={true}
      sceneDescription="Bloody Sunday"
      firstImage={data.BloodySundayStill}
      lastImage={data.BloodySundayReplay}
      videoSrc={Clip}
    />
  </div>
);

const query = graphql`
  query {
    BloodySundayStill: file(relativePath: { eq: "chapter-5/scene-6/images/ch-5-image-bloody-sunday-still@2x.png" }) {
      ...largeImage
    }
    BloodySundayReplay: file(relativePath: { eq: "chapter-5/scene-6/images/ch-5-image-bloody-sunday-replay@2x.png" }) {
      ...largeImage
    }
  }
`;

const ChapterComponent = scene(Chapter5Scene6);
export default (props) => {
  const data = useStaticQuery(query);
  return <ChapterComponent data={data} {...props}/>;
};
