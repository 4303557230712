import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Timeline from 'components/timelineComponent';
import FullScreenContainer from 'components/FullScreenContainer';
import Image from 'components/Image';

import 'styles/components/image-swap-angle.scss';
import { TweenMax, Expo } from 'gsap';

const propTypes = {
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  image2: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  alt: PropTypes.string.isRequired,
  alt2: PropTypes.string.isRequired,
  aspectRatio: PropTypes.number,
  offsetLeft: PropTypes.number,
  offsetRight: PropTypes.number,
  className: PropTypes.string,
};


class ImageSwapAngle extends Component {

  componentDidMount() {
    const {timeline, offsetLeft, offsetRight} = this.props

    const clipPoints1 = {
      y1: 100 + offsetLeft, y2: 100 + offsetRight
    }

    this.setClipPath(this.image2, clipPoints1)

    timeline.to(clipPoints1, 1, {y1: -offsetRight, y2: -offsetLeft, onUpdate: () => {
      this.setClipPath(this.image2, clipPoints1)
    }, ease: Expo.easeInOut})    
  }

  setClipPath = (target, clipPoints) => {
    const clipString=`polygon(0 ${clipPoints.y1}%, 100% ${clipPoints.y2}%, 100% 100%, 0% 100%)`
    TweenMax.set(target, {webkitClipPath: clipString})
  }
  

  render() {
    const { className, aspectRatio } = this.props

    return (
      <div className={`image-swap-angle ${className}`}>
        {aspectRatio &&
          <div className="image-swap-angle__aspect-ratio" style={{paddingTop: `${aspectRatio}%`}}>
            {this.renderContent()}
          </div>
        }
        {!aspectRatio &&
          <FullScreenContainer>
            {this.renderContent()}
          </FullScreenContainer>
        }
      </div>
    )
  }

  renderContent() {
    const {image, image2, alt, alt2, showAria1, showAria2} = this.props
    return (
      <div className="bg-container">
        <div className="image">
          <Image image={image} alt={alt} showAria={this.props.position < 0.1 && showAria1}/>
        </div>
        
        <div className="image2" ref={ref => this.image2 = ref}>
          <Image image={image2} alt={alt2} showAria={this.props.position > 0.8 && showAria2}/>
        </div>

      </div>
    )
  }
}


ImageSwapAngle.propTypes = propTypes;

ImageSwapAngle.defaultProps = {
  offsetLeft: 0,
  offsetRight: 0,
}

export default Timeline(ImageSwapAngle);
