import React, { Component } from 'react';
import { useStaticQuery, graphql } from "gatsby"

import './chapter-5-scene-11.scss';

import Timeline from 'components/timelineComponent';
import ImageSwapAngle from 'components/ImageSwapAngle';
import {Image} from 'components/Image';
import ScrollContainer from 'components/ScrollContainer';
import FullScreenImage from 'components/FullScreenImage';
import SplitView, {viewTypes} from 'templates/SplitView';
import FullScreenContainer from 'components/FullScreenContainer';
import {tabbable} from 'utils/accessibility'

import {getPercentageBetweenBounds} from 'utils';
import {
  placeholder, hideAllRefs, showElemWithAria, hideElementWithAria, makeElementTabbable, unmakeElementTabbable
} from 'utils/animation';
import scene from 'components/scene';
import {TimelineMarkers} from 'utils/scene'

// values retrieved from logging out the timeline's duration at the point
// at which we want these values to start/end
const imageSwapStart = 0;
const imageSwapEnd = 0.15;

class Chapter5Scene11 extends React.Component {
  render() {
    const { position, data } = this.props
    const {
      GasMask, TeenMarchers, Quote, LbjWindow, MapCloseup, DoodleDot, RevReeb,
      RevReebDead, Protest, Newspaper, LbjPhone, Jackson
    } = data
    const imageSwapProgress = getPercentageBetweenBounds(
        position, imageSwapStart, imageSwapEnd
    );

    return (
      <div className="chapter-5-scene-11">
        <div className="turnaround" ref="turnaround">
          <SplitView
            viewType={viewTypes.ONE_THIRD}
            fullWidthPanel={(
              <div className="turnaround__bg">
                <ImageSwapAngle
                  image={GasMask}
                  image2={TeenMarchers}
                  position={imageSwapProgress}
                  offsetLeft={100}
                  showAria2
                  alt=""
                  alt2="Civil rights marchers cross Edmund Pettus Bridge in the second attempt to march to Montgomery. The march was turned around by the police shortly after crossing the bridge. During the first attempted march police beat the civil rights activists. (Photo by © Flip Schulke/CORBIS/Corbis via Getty Images)"
                />
              </div>
            )}
            leftPanel={(
              <div className="turnaround__left">
                <p {...tabbable} {...tabbable} className="subhead" ref="turnaroundText1">
                  Reporters and many marchers are confused by King’s actions in what becomes known as the <span className="highlight">“Tuesday Turnaround.”</span>
                </p>
                <p {...tabbable} className="alt" ref="turnaroundText2">
                  Some, like James Forman of SNCC, see it as a betrayal.
                </p>
                <p {...tabbable} className="subhead" ref="turnaroundText3">
                  But King has avoided disobeying Judge Johnson’s order, and complied with President Johnson’s efforts to <span className="highlight">avoid a “Bloody Tuesday.”</span>
                </p>
              </div>
            )}
          />
        </div>

        <div className="lbj-msg" ref="lbjMsg">
          <SplitView
            leftPanel={(
              <div {...tabbable} className="lbj-msg__left">
                <p className="alt" ref="lbjMsgText1">
                  Later that day LBJ makes his first official comments<br/>on Bloody Sunday:
                </p>
                <div className="quote" ref="lbjMsgQuote">
                  <Image image={Quote} alt="Quote"/>
                </div>
                <p className="paragraph subhead" ref="lbjMsgText2">
                  I am certain Americans everywhere join<br/>in deploring the brutality with which a<br/>number of Negro citizens of Alabama were treated when they sought to dramatize their deep and sincere interest in attaining the precious right to vote.
                </p>
                <p className="alt" ref="lbjMsgText3">
                  LBJ promises to deliver a special message to Congress to ensure voting rights "for every American."
                </p>
              </div>
            )}
            rightPanel={(
              <div className="lbj-msg__right">
                <Image image={LbjWindow} showAria alt="LBJ through the window"/>
              </div>
            )}
          />
        </div>

        <div className="assault" ref="assault">
          <FullScreenImage
            image={MapCloseup}
            aspectRatio={8/5}
            alt="Assault"
            className="assault__img"
          >
            <div className="walker-cafe" ref="assaultWalker">
              <h4>Walker Cafe</h4>
              <div className="point-img">
                <Image image={DoodleDot} alt="Doodle Dot"/>
              </div>
            </div>
          </FullScreenImage>
          <FullScreenContainer className="rev-james">
            <ScrollContainer>
              <div className="rev-james__content">
                <div className="rev-imgs">
                  <div className="rev-img left" ref="assaultImageLeft">
                    <Image image={RevReeb} showAria alt="Rev. James J. Reeb, a Boston Minister who was savagely beaten by five white men in Selma 3/9 is reported in grave condition after brain surgery in Birmingham. Rev. Reeb and two other ministers of the Unitarian-Universalist were attacked in down town Selma. Rev. Reeb suffered multiple skull fractures."/>
                  </div>
                  <div className="rev-img right" ref="assaultImageRight">
                    <Image image={RevReebDead} showAria alt="Rev. James Reeb, 38, Boston Unitarian minister is shown unconscious in an ambulance that brought him to Birmingham hospital from Selma where he was critically beaten by five white men after he participated in integration march led by Dr. Martin Luther King Jr."/>
                  </div>
                </div>
                <p {...tabbable} className="subhead" ref="assaultImageText">
                  That evening three northern white ministers who had traveled to Selma are assaulted for supporting the march. One of them, Rev. James Reeb of Boston, dies of his wounds two days later.
                </p>
              </div>
            </ScrollContainer>
          </FullScreenContainer>
        </div>

        <div className="condolences" ref="condolences">
          <div className="condolences__back">
            <SplitView
              leftPanel={(
                <div className="back-img" ref="condolencesProtest">
                  <Image image={Protest} alt="Condolences protest"/>
                </div>
              )}
              rightPanel={(
                <div className="back-img newspaper-image" ref="condolencesNewspaper">
                  <Image image={Newspaper} showAria alt="James Reeb newspaper scan" caption="Used with permission of the Casper Star-Tribune"/>
                </div>

              )}
            />
          </div>
          <div className="condolences__front">
            <SplitView
              leftPanel={(
                <div className="front-left">
                  <p ref="condolencesText" className="subhead">
                    <span ref="condolencesText1">There is heavy media coverage of Reeb's death and President Johnsons's phone call of condolence to Reeb's widow.</span>
                    <span {...tabbable} ref="condolencesText2" className="condolences-text-2"> Jackson's friends and fellow activists contrasted the president's silence after Jimmie Lee was killed.</span>
                  </p>
                </div>
              )}
              rightPanel={(
                <div className="front-right">
                  <h4 ref="condolencesJljName">Jimmie Lee Jackson</h4>
                  <div className="jackson-img" ref="condolencesJljImg">
                    <Image image={Jackson} showAria alt="Jimmie Lee Jackson"/>
                  </div>
                  <div className="labels">
                    <h5 ref="condolencesActivist">-Activist</h5>
                    <h5 ref="condolencesDeacon">-Deacon</h5>
                    <h5 ref="condolencesGrandson">-Grandson</h5>
                  </div>
                </div>
              )}
            />
          </div>
          <div className="condolences__lbj">
            <FullScreenContainer>
              <div className="lbj-phone" ref="condolencesLbj">
                <Image image={LbjPhone} showAria alt="12/26/1963-Washington, DC: President Johnson conducts a great deal of business over the telephone. The exclusive photo shows him on the phone in his White House."/>
              </div>
            </FullScreenContainer>
          </div>
        </div>
      </div>
    );
  }
}

const animation = ({context, timeline, easeOut, easeIn}) => {
  const {
    turnaround, turnaroundText1, turnaroundText2, turnaroundText3,
    lbjMsg, lbjMsgText1, lbjMsgQuote, lbjMsgText2, lbjMsgText3,
    assault, assaultWalker, assaultImageLeft, assaultImageRight, assaultImageText,
    condolences, condolencesProtest, condolencesNewspaper,
    condolencesText,condolencesText1, condolencesText2,
    condolencesJljName, condolencesJljImg, condolencesActivist,
    condolencesDeacon, condolencesGrandson, condolencesLbj,
  } = context.refs;

  hideAllRefs(context.refs, ['turnaround', 'condolencesText1']);

  timeline.fromTo(turnaroundText1, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}
  );
  timeline.fromTo(turnaroundText2, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=1.25"
  );
  timeline.fromTo(turnaroundText3, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=1.25"
  );
  // console.log(timeline.duration())

  placeholder(timeline, 0.75)
  // console.log(timeline.duration())

  timeline.markers.createTimelineMarker()

  timeline.to(turnaround, 1, {...hideElementWithAria});
  timeline.to(lbjMsg, 1, {...showElemWithAria}, '-=0.5');
  timeline.fromTo(lbjMsgText1, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=0.5"
  );
  timeline.fromTo(lbjMsgQuote, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=1.25"
  );
  timeline.fromTo(lbjMsgText2, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=1.5"
  );
  timeline.fromTo(lbjMsgText3, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=1.25"
  );

  timeline.markers.createTimelineMarker()

  timeline.to(lbjMsg, 1, {...hideElementWithAria});
  timeline.to(assault, 1, {...showElemWithAria}, '-=1');
  timeline.to(assaultWalker, 0.5, {...showElemWithAria});
  placeholder(timeline, 0.25)
  timeline.to(assaultImageLeft, 1.5,
      {...showElemWithAria}
  );
  timeline.to(assaultImageRight, 1.5,
      {...showElemWithAria}, "-=1.5"
  );
  // timeline.set(assaultWalker,{...hideElementWithAria});
  timeline.fromTo(assaultImageText, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=0.75"
  );
  // placeholder(timeline, 0.3);
  timeline.markers.createTimelineMarker()

  timeline.to(assault, 1, {...hideElementWithAria});
  timeline.to(condolences, 1, {...showElemWithAria}, '-=0.5');
  timeline.to(condolencesProtest, 1, {...showElemWithAria}, '-=1');
  timeline.to(condolencesNewspaper, 1, {...showElemWithAria}, '-=1');
  timeline.to(condolencesLbj, 1, {...showElemWithAria});
  timeline.fromTo(condolencesText, 1.5,
      {y: '+=50'}, {y: '-=50', ...showElemWithAria, ...easeOut}, "-=1"
  );

  // placeholder(timeline, 0.5);
  timeline.markers.createTimelineMarker()
  timeline.to(condolencesText1, 0, {...makeElementTabbable})
  timeline.to(condolencesProtest, 1, {...hideElementWithAria});
  timeline.to(condolencesNewspaper, 1, {...hideElementWithAria}, '-=1');
  timeline.to(condolencesLbj, 1, {...hideElementWithAria}, '-=1');
  timeline.to(condolencesText1, 1, {autoAlpha: 0.3, ...unmakeElementTabbable});
  timeline.to(condolencesText2, 1, {...showElemWithAria}, "-=1"
  );
  timeline.to([condolencesJljImg, condolencesJljName, condolencesActivist, condolencesDeacon, condolencesGrandson,], 1, {...showElemWithAria}, '-=1');

  timeline.markers.createTimelineMarker()

  // console.log(timeline.duration())
};

const query = graphql`
  query {
    GasMask: file(relativePath: { eq: "chapter-5/scene-11/images/ch5-image-attacks_marchers-red.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1848) {
          ...selmaFluidImage
        }
      }
    }

    TeenMarchers: file(relativePath: { eq: "chapter-5/scene-11/images/ch5-image-attacks_marchers-bw.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1848) {
          ...selmaFluidImage
        }
      }
    }

    Quote: file(relativePath: { eq: "chapter-2/scene-9/images/quote@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 72) {
          ...selmaFluidImage
        }
      }
    }

    LbjWindow: file(relativePath: { eq: "chapter-5/scene-11/images/ch-5-image-lbj-window@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    MapCloseup: file(relativePath: { eq: "chapter-5/scene-11/images/ch-5-image-map-closeup-bg@2x.png" }) {
      ...largeImage
    }

    DoodleDot: file(relativePath: { eq: "chapter-5/scene-11/images/ch-5-doodle-dot@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 110) {
          ...selmaFluidImage
        }
      }
    }

    RevReeb: file(relativePath: { eq: "chapter-5/scene-11/images/ch-5-image-reeb@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 824) {
          ...selmaFluidImage
        }
      }
    }

    RevReebDead: file(relativePath: { eq: "chapter-5/scene-11/images/ch-5-image-reeb-dead@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 1040) {
          ...selmaFluidImage
        }
      }
    }

    Protest: file(relativePath: { eq: "chapter-5/scene-11/images/ch-5-image-reeb-protest@2x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    Newspaper: file(relativePath: { eq: "chapter-5/scene-11/images/ch-5-image-reeb-newspaper@2x.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...selmaFluidImage
        }
      }
    }

    LbjPhone: file(relativePath: { eq: "chapter-5/scene-11/images/ch-5-image-lbj-on-phone@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 2880) {
          ...selmaFluidImage
        }
      }
    }

    Jackson: file(relativePath: { eq: "chapter-5/scene-11/images/ch-5-iamge-jimmie-lee-jackson@2x.png" }) {
      childImageSharp {
        fluid(maxWidth: 638) {
          ...selmaFluidImage
        }
      }
    }
  }
`

const SceneComponent = scene(Timeline(Chapter5Scene11, {animation}))
export default (props) => {
  const data = useStaticQuery(query)
  return <SceneComponent data={data} {...props}/>
}
