import React from 'react';
import PropTypes from 'prop-types';

import {Image} from './Image';
import '../styles/components/actor-portrait.scss';

const ActorPortrait = ({img, actorName, description}) => (
  <div className="actor-portrait">
    <div className="actor-portrait__img">
      <Image image={img}/>
    </div>
    <h3>{actorName}</h3>
    <p className="alt">{description}</p>
  </div>
);

ActorPortrait.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  actorName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ActorPortrait;
